<template>
  <div class="setQuestionTc">
    <el-dialog
      title="设置审核教师"
      :visible.sync="dialogVisible"
      width="592px"
      :close-on-click-modal="false"
    >
      <div class="filter-box">
        <el-select
          v-model="search.levelId"
          placeholder="请选择学段"
          @change="getSchoolTeacherList()"
        >
          <el-option label="全部" value=""> </el-option>
          <el-option
            v-for="item in levelOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="search.year"
          placeholder="请选择学年"
          @change="getSchoolTeacherList()"
        >
          <el-option label="全部" value=""> </el-option>
          <el-option
            v-for="item in yearOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="search.subjectId"
          placeholder="请选择科目"
          @change="getSchoolTeacherList()"
        >
          <el-option label="全部" value=""> </el-option>
          <el-option
            v-for="item in subjectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <a-transfer
        :key="newKey"
        :data-source="teacherList"
        :titles="['可选', '已选']"
        show-search
        :filter-option="filterOption"
        :target-keys="targetKeys"
        :render="(item) => item.title"
        @change="handleChange"
      >
        <!-- <template slot="footer">11111</template> -->
      </a-transfer>
      <div class="time-box">
        <el-checkbox v-model="effectiveTime" :true-label="1" :false-label="0"
          >限时审核</el-checkbox
        >
        <el-tooltip
          class="item"
          effect="dark"
          content="教师仅可在此时间段内操作审核，逾期未审将自动设为同意。"
          placement="top"
        >
          <i class="el-icon-question"></i>
        </el-tooltip>
        <el-date-picker
          v-model="checkTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm"
          format="yyyy-MM-dd HH:mm"
          :disabled="!effectiveTime"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="getNext()"> 下一步 </el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="管理审核教师"
      :visible.sync="setDialogVisible"
      width="465px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-input
        v-model="keyword"
        class="search-input"
        placeholder="请输入搜索内容"
        @keyup.enter.native="handleClick"
      >
        <i
          slot="suffix"
          class="el-icon-search el-input__icon"
          @click="handleClick"
        >
        </i
      ></el-input>
      <el-table :data="tabTeacher" border>
        <el-table-column
          prop="title"
          label="教师姓名"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="审核同类题"
          width="100"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.authorityA"
              active-color="#2474ED"
              inactive-color="#DEE0E7"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          width="130"
          prop="address"
          label="审核分层拓展题"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.authorityB"
              active-color="#2474ED"
              inactive-color="#DEE0E7"
            >
            </el-switch
          ></template>
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" @click="delTeacher(row)"
              >移除</el-button
            ></template
          >
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { levelOptions } from "@/core/util/constdata";
import { getYear, filterSubject } from "@/core/util/util";
import {
  getExampaperteacherreview,
  getTeacherList,
} from "@/core/api/exam/exampaper";
import {
  exampaperteacherreviewSave,
  trainingTime,
} from "@/core/api/exam/examLore";

import moment from "moment";
export default {
  name: "SetQuestionTc",
  data() {
    return {
      newKey: 1,
      dialogVisible: false,
      value: "0",
      setDialogVisible: false,
      btnLoading: false,
      levelOptions: levelOptions(),
      yearOptions: getYear(),
      subjectOptions: [],
      tabTeacher: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() + 86400 * 1000 < Date.now();
        },
      },
      search: {
        levelId: "",
        year: "",
        subjectId: "",
      },
      keyword: "",
      effectiveTime: "",
      checkTime: [],
      teacherList: [],
      targetKeys: [],
      examineForm: {},
    };
  },
  created() {},
  methods: {
    handleClose() {
      this.newKey = new Date().getTime();
      this.setDialogVisible = false;
      this.dialogVisible = true;
    },
    delTeacher(item) {
      if (this.tabTeacher.length == 1) {
        this.$message({
          showClose: true,
          message: "至少选择一位教师!",
          type: "error",
        });
        return;
      }
      this.tabTeacher = this.tabTeacher.filter(
        (index) => index.key != item.key
      );
      this.oldtabTeacher = this.oldtabTeacher.filter(
        (index) => index.key != item.key
      );
      this.targetKeys = this.targetKeys.filter((index) => index != item.key);
      this.oldTeacher = this.teacherList.filter(
        (item) => this.targetKeys.indexOf(item.key) != -1
      );
    },
    getNext() {
      let tabTeacher = this.teacherList.filter(
        (item) => this.targetKeys.indexOf(item.key) != -1
      );
      if (tabTeacher.length == 0) {
        this.$message({
          showClose: true,
          message: "至少选择一位教师!",
          type: "error",
        });
        return;
      }
      if (!this.checkTime) {
        this.$message({
          showClose: true,
          message: "请选择审核时间!",
          type: "error",
        });
        return;
      }
      if (this.effectiveTime == 1 && this.checkTime.length == 0) {
        this.$message({
          showClose: true,
          message: "请选择审核时间!",
          type: "error",
        });
        return;
      }
      this.tabTeacher = tabTeacher.map((item) => {
        item.authorityA = true;
        item.authorityB = true;
        this.$parent.paperTeacherList.map((index) => {
          if (index.teacherId == item.key) {
            if (index.authority == 0) {
              item.authorityA = true;
              item.authorityB = true;
            }
            if (index.authority == 1) {
              item.authorityA = true;
              item.authorityB = false;
            }
            if (index.authority == 2) {
              item.authorityA = false;
              item.authorityB = true;
            }
          }
        });
        return item;
      });
      this.tabTeacher = JSON.parse(JSON.stringify(this.tabTeacher));
      this.oldtabTeacher = JSON.parse(JSON.stringify(this.tabTeacher));
      this.keyword = "";
      this.setDialogVisible = true;
      this.dialogVisible = false;
    },
    handleClick() {
      this.oldtabTeacher = this.oldtabTeacher.map((item) => {
        this.tabTeacher.map((index) => {
          if (item.key == index.key) {
            Object.assign(item, index);
          }
        });
        return item;
      });
      this.tabTeacher = this.oldtabTeacher.filter(
        (item) => item.title.indexOf(this.keyword) != -1
      );
      //   this.setDialogVisible = true;
    },
    submit() {
      let type = false;
      this.oldtabTeacher = this.oldtabTeacher.map((item) => {
        this.tabTeacher.map((index) => {
          if (item.key == index.key) {
            Object.assign(item, index);
          }
        });
        return item;
      });
      let arr = this.oldtabTeacher.map((item) => {
        item.teacherId = item.key;
        item.teacherName = item.title;
        if (!item.authorityA && item.authorityB) {
          item.authority = 2;
        }
        if (!item.authorityA && !item.authorityB) {
          type = true;
        }
        if (item.authorityA && !item.authorityB) {
          item.authority = 1;
        }
        if (item.authorityA && item.authorityB) {
          item.authority = 0;
        }
        return item;
      });
      if (type) {
        this.$message({
          showClose: true,
          message: "每位教师至少应允许审核一种题型!",
          type: "error",
        });
        return;
      }
      // console.log(arr);
      this.subTeacher(arr);
    },
    async subTeacher(arr) {
      this.btnLoading = true;
      await this.setTrainingTime();
      let data = {};
      let paperId = this.$parent.examPapers.filter(
        (item) => item.subjectId == this.$parent.currentSubjectId
      )[0].id;
      data.examPaperId = paperId;

      data.list = arr;
      exampaperteacherreviewSave(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "设置成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.setDialogVisible = false;
          this.$parent.getNewStatus(paperId);
          setTimeout(() => {
            this.btnLoading = false;
          }, 500);
        })
        .catch(() => {
          this.btnLoading = false;
        });
      //   this.setDialogVisible = true;
    },
    async setTrainingTime() {
      let data = {
        effectiveTime: this.effectiveTime,
      };
      if (this.effectiveTime == 1) {
        data.beginTime = this.checkTime[0];
        data.endTime = this.checkTime[1];
      }
      let paperId = this.$parent.examPapers.filter(
        (item) => item.subjectId == this.$parent.currentSubjectId
      )[0].id;
      data.examPaperId = paperId;
      await trainingTime(data)
        .then(() => {})
        .catch(() => {
          this.btnLoading = false;
        });
    },
    getMock() {
      getExampaperteacherreview(this.search).then(() => {
        this.examineForm = {};
      });
    },
    async getSchoolTeacherList() {
      this.teacherLoading = true;
      const { schoolId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      let data = {
        schoolId: schoolId,
      };
      Object.assign(data, this.search);
      const res = await getTeacherList(data);
      let ids = [];
      let array = res.data.data.map((item) => {
        let data = {
          title: item.name,
          key: item.id.toString(),
        };
        ids.push(item.id.toString());
        return data;
      });
      if (this.oldTeacher && this.oldTeacher.length > 0) {
        this.oldTeacher.map((item) => {
          if (ids.indexOf(item.key) == -1) {
            array.push(item);
          }
        });
      }
      this.teacherList = array;
    },
    filterOption(inputValue, option) {
      return option.title.indexOf(inputValue) > -1;
    },
    handleChange(targetKeys) {
      this.targetKeys = targetKeys;
      this.oldTeacher = this.teacherList.filter(
        (item) => this.targetKeys.indexOf(item.key) != -1
      );
    },

    init() {
      //   console.log(this.$parent.currentExamPaper);
      this.search.level = this.$parent.currentExamPaper.level.toString();
      this.search.year = this.$parent.currentExamPaper.year.toString();
      this.search.subjectId = this.$parent.currentExamPaper.subjectId;
      this.search.examId = this.$route.query.id;
      this.subjectOptions = filterSubject(this.search.level);
      // this.getMock();
      this.getSchoolTeacherList();
      let time = new Date().getTime() + 86400 * 1000;
      this.checkTime = [
        moment().format("yyyy-MM-DD HH:mm"),
        moment(time).format("yyyy-MM-DD HH:mm"),
      ];
      // console.log();
      if (this.$parent.indexPaper.trainingTimeEffective == 1) {
        let { trainingTimeEnd, trainingTimeBegin } = this.$parent.indexPaper;
        this.checkTime = [
          moment(trainingTimeBegin).format("yyyy-MM-DD HH:mm"),
          moment(trainingTimeEnd).format("yyyy-MM-DD HH:mm"),
        ];
        this.effectiveTime = 1;
      } else {
        this.effectiveTime = 0;
        this.checkTime = [
          moment().format("yyyy-MM-DD HH:mm"),
          moment(time).format("yyyy-MM-DD HH:mm"),
        ];
      }
      if (this.$parent.paperTeacherList.length > 0) {
        this.targetKeys = this.$parent.paperTeacherList.map((item) =>
          item.teacherId.toString()
        );
        this.oldTeacher = this.$parent.paperTeacherList.map((item) => {
          return {
            title: item.teacherName,
            key: item.teacherId.toString(),
          };
        });
      } else {
        this.targetKeys = [];
        this.oldTeacher = [];
      }

      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.setQuestionTc {
  .search-input {
    width: 218px;
  }

  .el-table {
    margin-top: 18px;
  }

  .filter-box {
    margin-bottom: 18px;
  }

  .el-select {
    width: 120px;
    margin-right: 18px;
  }

  .time-box {
    margin-top: 18px;

    i {
      margin: 0 4px;
      color: #2474ed;
    }
  }

  ::v-deep {
    .ant-transfer-list {
      width: 251px;
      height: 350px;
    }
  }
}
</style>
