<template>
  <div class="copyExam">
    <!--复制其他考试试卷弹窗-->

    <el-dialog
      :visible.sync="copyExamPaperDialogVisible"
      title="复制其他考试试卷"
      :lock-scroll="false"
      width="1000px"
      class="copy-exam-paper"
    >
      <div class="search">
        <div class="searchItem">
          <span class="searchItemTitle">复制方式:</span>
          <el-select
            v-model="copyExamPaperForm.searchMode"
            style="width: 100px"
            placeholder="复制方式"
            @change="checkType()"
          >
            <el-option
              label="复制其他考试试卷到当前考试试卷"
              :value="1"
            ></el-option>
            <el-option
              v-if="!$route.query.ifVirtual"
              label="复制当前考试试卷到其他考试试卷"
              :value="2"
            ></el-option>
          </el-select>
        </div>

        <div class="searchItem">
          <span class="searchItemTitle">学校:</span>
          <el-select
            v-model="copyExamPaperForm.schoolId"
            style="width: 120px"
            placeholder="请选择学校"
            clearable
            filterable
            @change="getNewPaper"
          >
            <el-option
              v-for="item in copyExamPaperSchoolOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="searchItem">
          <span class="searchItemTitle">年级:</span>
          <el-select
            v-model="copyExamPaperForm.resGradeId"
            style="width: 80px"
            placeholder="请选择年级"
            clearable
            filterable
            @change="getNewPaper"
          >
            <el-option
              v-for="item in copyExamPaperResGradeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="searchItem">
          <span class="searchItemTitle">科目:</span>
          <el-select
            v-model="copyExamPaperForm.subjects"
            style="width: 150px"
            placeholder="请选择年级"
            multiple
            collapse-tags
            @change="getNewPaper"
          >
            <el-option
              v-for="item in $parent.subjectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="searchItem">
          <span class="searchItemTitle">考试名称:</span>
          <el-input
            v-model="copyExamPaperForm.examName"
            placeholder="输入考试名称"
            style="width: 140px"
            @keyup.enter.native="getNewPaper"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getNewPaper"
            ></el-button>
          </el-input>
        </div>
      </div>
      <div v-if="!$route.query.ifVirtual" class="tips-err">
        复制时注意复制试卷和被复制试卷的科目关系<br />
        例如：不能将语文的试题复制到数学试卷。可以将理综合等综合类科目试卷复制到其试题包含的科目，比如物理、化学等。
      </div>
      <el-table :data="copyExamPaperDataList" border style="width: 100%">
        <el-table-column align="center" prop="date" label="选择" width="60px">
          <template slot-scope="{ row }">
            <el-checkbox-group v-model="copyExamPaperIds">
              <el-checkbox :label="row.id"> {{ " " }}</el-checkbox>
            </el-checkbox-group>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="schoolName"
          label="学校"
          width="200px"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="resGradeName"
          label="年级"
          width="80px"
        >
        </el-table-column>
        <el-table-column align="center" prop="examName" label="考试名称">
        </el-table-column>
        <el-table-column
          align="center"
          prop="examDate"
          label="考试时间"
          width="120px"
        >
        </el-table-column>
      </el-table>

      <el-pagination
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        :total="copyExamPaperCount"
        :current-page="current"
        @current-change="reGetPaperList"
      >
      </el-pagination>

      <span slot="footer" class="dialog-footer">
        <el-button
          class="cancelButton"
          @click="copyExamPaperDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          v-if="copyExamPaperForm.searchMode == 1"
          type="primary"
          :loading="loading"
          :disabled="copyExamPaperIds.length == 0"
          @click="toLint()"
        >
          复制到当前考试
        </el-button>
        <el-button
          v-if="copyExamPaperForm.searchMode == 2"
          :loading="btnLoading"
          type="primary"
          :disabled="copyExamPaperIds.length == 0"
          @click="setCopyexampaperto()"
        >
          当前考试复制到其他考试
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getExamPaperStepFinishedList,
  copyexampapermulti,
  copyexampaperto,
  copyExamPaper,
} from "@/core/api/exam/exampaper";
import { getSchoolList } from "@/core/api/school/school";
import { gradeHighOptions, gradeMiddleOptions } from "@/core/util/constdata";
export default {
  name: "CopyExam",
  data() {
    return {
      copyExamPaperDialogVisible: false, // 复制其他考试试卷弹窗
      loading: false,
      btnLoading: false,
      copyExamPaperId: "",
      copyExamPaperIds: [],
      copyType: 1,
      copyExamPaperDataList: [],
      copyExamPaperSchoolOptions: [],
      copyExamPaperResGradeOptions: [],
      copyExamPaperSize: 10,
      copyExamPaperIndex: 1,
      copyExamPaperCount: 0,
      current: 1,
      copyExamPaperForm: {
        examName: "",
        schoolId: "",
        resGradeId: "",
        searchMode: 1,
        subjects: [],
      },
    };
  },
  created() {},
  methods: {
    reGetPaperList(index) {
      this.current = index;
      this.getPapersList();
    },
    initCopyExamPaperDialogSelect() {
      getSchoolList().then((response) => {
        if (response.data.data && response.data.data.length > 0) {
          response.data.data.forEach((item) => {
            this.copyExamPaperSchoolOptions.push({
              value: item.id,
              label: item.name,
            });
          });
        }
      });
      if (this.$parent.examLevel === 2) {
        // 初中
        this.copyExamPaperResGradeOptions = gradeMiddleOptions;
      } else if (this.$parent.examLevel === 3) {
        // 高中
        this.copyExamPaperResGradeOptions = gradeHighOptions;
      }
    },
    toLint() {
      if (this.copyExamPaperIds.length > 1) {
        // this.setCopyexampapermulti()
        this.$confirm(
          "其他科目原卷会被忽略，请用户确保多个科目的原卷一致!",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "error",
          }
        )
          .then(() => {
            this.setCopyexampapermulti();
          })
          .catch(() => {});
      } else {
        this.setCopyexampapermulti();
      }
    },
    setCopyexampapermulti() {
      let data = {
        copyExamPaperIds: this.copyExamPaperIds.join(","),
        examPaperId: this.$parent.currentExamPaper.id,
      };
      this.loading = true;
      copyexampapermulti(data)
        .then(() => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: "复制成功,数据更新可能延迟,可以稍后刷新再试!",
            type: "success",
          });
          this.copyExamPaperDialogVisible = false;
          setTimeout(() => {
            this.$parent.getExamPaperInfo(this.$parent.currentExamPaper.id);
          }, 1000);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setCopyexampaperto() {
      let data = {
        copyExamPaperId: this.$parent.currentExamPaper.id,
        examPaperIds: this.copyExamPaperIds.join(","),
      };
      this.btnLoading = true;
      copyexampaperto(data)
        .then(() => {
          this.btnLoading = false;
          this.$message({
            showClose: true,
            message: "复制成功,请前往选择考试查看!",
            type: "success",
          });
          this.copyExamPaperDialogVisible = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    confirmCopyExamPaperDialog() {
      if (!this.copyExamPaperId || this.copyExamPaperId.length === 0) {
        this.$message.error("请选择考试！");
        return;
      }
      this.loading = true;
      let data = new URLSearchParams();
      data.append("examId", this.$parent.examId);
      data.append("examLevel", this.$parent.examLevel);
      data.append("examPaperId", this.$parent.currentExamPaper.id);
      data.append("subjectId", this.$parent.currentSubjectId);
      data.append("copyExamPaperId", this.copyExamPaperId);
      copyExamPaper(data).then(() => {
        this.$parent.getExamPaperInfo(this.$parent.currentExamPaper.id);

        this.loading = false;
      });
    },
    init() {
      this.copyExamPaperDialogVisible = true;
      this.copyExamPaperIds = [];
      Object.assign(
        this.$data.copyExamPaperForm,
        this.$options.data().copyExamPaperForm
      );
      this.copyExamPaperForm.subjects = [this.$parent.currentSubjectId];

      this.initCopyExamPaperDialogSelect();
      this.getNewPaper();
    },
    checkType() {
      this.copyExamPaperIds = [];
      this.getNewPaper();
    },
    getNewPaper() {
      this.current = 1;
      this.getPapersList();
    },
    getPapersList() {
      let data = {
        size: this.copyExamPaperSize,
        level: this.$parent.examLevel,
        current: this.current,
      };

      Object.assign(data, this.copyExamPaperForm);
      data.subjectIds = this.copyExamPaperForm.subjects.join(",");

      getExamPaperStepFinishedList(data).then((res) => {
        let ids = "";
        let ids1 = this.copyExamPaperForm.subjects.join(",");

        if (res.config.data) {
          ids = JSON.parse(res.config.data).subjectIds;
        }
        // console.log(ids != ids1);
        if (ids != ids1) return;
        this.copyExamPaperCount = res.data.data.total;
        this.copyExamPaperDataList = res.data.data.records.map((item) => {
          item.checked = false;
          return item;
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.copyExam {
  .tips-err {
    color: red;
    margin-bottom: 12px;
    font-size: 12px;
  }

  .search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    .searchItemTitle {
      margin-right: 8px;
    }
  }

  .copy-exam-paper {
    .el-dialog__body {
      padding: 10px 50px;

      .copy-content {
        display: flex;

        .table {
          .el-checkbox {
            margin: 0;
            line-height: 30px;
          }
        }
      }
    }
  }
}
</style>
