<template>
  <div class="uploadFile">
    <!--上传试卷-->
    <el-dialog
      class="upload-exam-paper-dialog"
      title="上传试卷"
      :visible.sync="uploadExamPaperDialogVisible"
      :lock-scroll="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="638px"
    >
      <div class="upload-exam-paper-dialog-header">
        <div
          class="filetype"
          :class="{ 'selected-filetype': uploadExamPaperFileType === 2 }"
          @click="changeUploadExamPaperFileType(2)"
        >
          PDF文件上传
        </div>
        <div
          class="filetype"
          :class="{ 'selected-filetype': uploadExamPaperFileType === 3 }"
          @click="changeUploadExamPaperFileType(3)"
        >
          图片文件批量上传
        </div>
        <div
          class="filetype"
          :class="{ 'selected-filetype': uploadExamPaperFileType === 1 }"
          @click="changeUploadExamPaperFileType(1)"
        >
          Word文件上传
        </div>
        <div
          class="filetype"
          :class="{ 'selected-filetype': uploadExamPaperFileType === 4 }"
          @click="changeUploadExamPaperFileType(4)"
        >
          扫描上传
        </div>
      </div>
      <div
        v-if="uploadExamPaperFileType != 4"
        class="upload-exam-paper-dialog-content"
      >
        <el-upload
          ref="uploadExamPaper"
          :drag="true"
          :action="uploadExamPaperURL"
          :headers="uploadExamPaperHeaders"
          :accept="uploadExamPaperAccept"
          :multiple="uploadExamPaperMultiple"
          :limit="uploadExamPaperLimit"
          :file-list="uploadExamPaperFileListEle"
          :auto-upload="false"
          :on-change="uploadExamPaperOnChange"
          :before-remove="uploadExamPaperBeforeRemove"
          :on-exceed="uploadExamPaperOnExceed"
          :http-request="uploadExamPaperHttpRequest"
        >
          <div class="upload-text">
            将{{
              uploadExamPaperFileType === 1
                ? " Word "
                : uploadExamPaperFileType === 2
                ? " PDF "
                : "图片"
            }}文件拖放到此处或<br />点击上传
          </div>
        </el-upload>
      </div>
      <template v-if="uploadExamPaperFileType == 4">
        <div class="upload-scan-header">
          <div>
            状态:
            <span v-if="clientSideLinkType" class="succ"> 连接正常 </span>
            <span v-else class="err">
              <span v-if="returnMsg" class="waring">{{ returnMsg }}</span>
              <template v-else>扫描客户端未运行</template>
            </span>
            立即
            <el-button type="text" @click="downClient()">
              下载扫描客户端
            </el-button>
          </div>
          <el-button
            v-if="scanImgArr.length > 0"
            type="text"
            @click="rotatingImgAll()"
            >全部旋转90°</el-button
          >
        </div>
        <div v-if="scanImgArr.length == 0" class="upload-scan">
          <span class="tips-scan">
            当前暂无试卷图片<br />
            为了提升图片识别效率，请确保所扫试卷方向一致和顺序正确
          </span>
        </div>

        <div v-if="scanImgArr.length > 0">
          <SlickList
            v-model="scanImgArr"
            class="edit-scroll-style upload-scan-item"
            axis="xy"
            :distance="0.5"
            @input="getChangeLists"
          >
            <SlickItem
              v-for="(item, index) in scanImgArr"
              :key="index"
              :index="index"
              class="scan-item"
            >
              <div class="scan-item-img">
                <el-image :src="item" :preview-src-list="scanImgArr">
                </el-image>

                <div
                  class="op-iocn op-iocn-a"
                  @click.stop="rotatingImg(item, index)"
                >
                  <img src="@/assets/exam/examItem/icon_rotate.png" alt="" />
                </div>
                <div class="op-iocn" @click.stop="delItem(index)">
                  <img src="@/assets/exam/examItem/icon_del.png" alt="" />
                </div>
              </div>
              <div class="index-nums">第{{ capitalNumberFn(index + 1) }}张</div>
            </SlickItem>
          </SlickList>
          <div class="img-msg-ctn">
            注意：1、请确保图片方向正确，如需调整请旋转图片；2、请确保图片依次排列，如需调整，请拖动图片对调顺序，上传中不可进行此操作；3、点击图片可查看大图；
          </div>
        </div>
      </template>
      <span
        slot="footer"
        :key="uploadExamPaperFileType == 4"
        class="dialog-footer"
      >
        <template v-if="uploadExamPaperFileType == 4">
          <template v-if="scanImgArr.length == 0">
            <el-button
              type="primary"
              :disabled="!clientSideLinkType"
              :loading="loadingUploadExamPaperDialog"
              @click="sendMsg(1)"
            >
              开始扫描
            </el-button>
          </template>
          <template v-else>
            <el-button
              :disabled="!clientSideLinkType || loadingUploadExamPaperDialog"
              @click="sendMsg(2)"
            >
              继续扫描
            </el-button>
            <el-button
              :disabled="!clientSideLinkType || loadingUploadExamPaperDialog"
              @click="sendMsg(1)"
            >
              重新扫描
            </el-button>
            <el-button
              type="primary"
              :loading="loadingUploadExamPaperDialog"
              @click="uploadexampaperByScan()"
            >
              确 定
            </el-button>
          </template>
        </template>
        <template v-else>
          <el-button
            type="primary"
            :loading="loadingUploadExamPaperDialog"
            @click="uploadExamPaper"
          >
            上 传
          </el-button>
        </template>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getStore, removeStore } from "@/core/util/store";
import {
  uploadExamPaper,
  uploadexampaperByScan,
} from "@/core/api/exam/exampaper";
import { downClient } from "@/core/util/util.js";
import { SlickList, SlickItem } from "vue-slicksort";
// import { rotatingPicture } from "@/core/util/imgUtils";
import { capitalNumberFn } from "@/core/util/util";
import { websocketCommand } from "@/core/util/websocket.js";
import { rotateImage } from "@/core/api/exam/exampaper";
export default {
  name: "UploadFile",
  components: { SlickItem, SlickList },
  data() {
    return {
      clientSideLinkType: false,
      returnMsg: "",
      uploadExamPaperDialogVisible: false,
      uploadExamPaperURL: "/exam/exampaper/uploadexampaper",
      uploadExamPaperHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
      },
      uploadExamPaperFileType: 2, // 上传试卷类型 1-Word文件 2-PDF文件 3-图片文件批量
      uploadExamPaperFileData: new FormData(),
      uploadExamPaperFileList: [],
      uploadExamPaperAccept: ".docx",
      uploadExamPaperMultiple: false,
      uploadExamPaperLimit: 1,
      uploadExamPaperFileListEle: [],
      loadingUploadExamPaperDialog: false,
      scanImgArr: [],
      comKey: 1,
    };
  },
  created() {
    // for (let i = 0; i < 10; i++) {
    //   this.scanImgArr.push(img);
    // }
    this.createWs();
  },
  methods: {
    receiveMsg(data) {
      this.clientData = data;
      // console.log(data);
      this.returnMsg = "";
      this.btnDisabled = false;
      this.openClient = true;
      if (data.returnCode == 200) {
        if (data.images) {
          data.images.split("，").forEach((item) => {
            this.scanImgArr.push(item);
          });
        }
        if (data.scannerStatus == 0) {
          this.clientSideLinkType = false;
          this.returnMsg = "未连接扫描仪";
          this.clientLoading = false;
          this.clientSideType = false;
          this.btnDisabled = true;
          this.loadingUploadExamPaperDialog = false;
        }
        if (data.scannerStatus == 1) {
          this.returnMsg = "扫描仪无纸张";
          this.clientLoading = false;
          this.loadingUploadExamPaperDialog = false;
          this.$alert(`扫描仪无纸张`, "提示", {
            confirmButtonText: "确定",
            callback: () => {
              this.clientLoading = false;
              this.clientSideType = true;
            },
          });
        }
        if (data.scannerStatus == 2) {
          this.returnMsg = "扫描仪错误";
          this.$alert(`扫描仪错误`, "提示", {
            confirmButtonText: "确定",
            callback: () => {},
          });
          this.loadingUploadExamPaperDialog = false;
          this.clientLoading = false;
          this.clientSideType = false;
        }
        // 已连接扫描仪
        if (data.scannerStatus == 3) {
          this.clientSideType = true;
          this.clientSideLinkType = true;
          this.clientSideId = data.clientId;
        }
        // 扫描仪有任务
        if (data.scannerStatus == 4) {
          this.clientSideType = true;
          this.clientSideLinkType = true;
          this.clientSideId = data.clientId;
          this.$alert(`扫描任务正在进行中，请稍后！`, "提示", {
            confirmButtonText: "确定",
            callback: () => {},
          });
        }
        if (data.scannerStatus == 5) {
          // if (this.indexReq != 2) {
          this.loadingUploadExamPaperDialog = false;
          this.clientLoading = false;
          this.scannerStatus = data.scannerStatus;
          if (data.action == "startScan") {
            this.$message({
              message: `扫描完成!`,
              type: "success",
              duration: 2000,
              showClose: true,
            });
          }
        }
        if (data.scannerStatus == 6) {
          // this.$message({
          //   showClose: true,
          //   message: `模板已上传，请等待校验！`,
          //   type: "success",
          // });
          this.clientLoading = true;
        }
        if (data.scannerStatus == 7) {
          this.$message({
            showClose: true,
            message: `扫描上传失败`,
            type: "error",
            duration: 3000,
          });
          this.loadingUploadExamPaperDialog = false;
          this.clientLoading = false;
        }
        if (data.scannerStatus == 8) {
          this.$message({
            showClose: true,
            message: `扫描已取消`,
            type: "success",
          });
          this.loadingUploadExamPaperDialog = false;
          this.clientLoading = false;
        }
        if (data.scannerStatus == 9) {
          this.$alert(`磁盘空间低于500M，请清理后上传！`, "提示", {
            confirmButtonText: "确定",
            callback: () => {},
          });
          this.loadingUploadExamPaperDialog = false;
          // this.btnDisabled = true;
          this.clientLoading = false;
          this.clientSideType = false;
        }
      } else {
        this.loadingUploadExamPaperDialog = false;
        this.clientSideType = false;
        this.clientSideLinkType = false;
        this.openClient = false;
        this.clientLoading = false;
        this.btnDisabled = true;
        if (!data.returnCode) {
          return;
        }

        let str = "";
        if (data.returnCode == 201) {
          str = "未识别的action";
        }
        if (data.returnCode == 202) {
          str = "请求参数错误";
        }
        if (data.returnCode == 203) {
          str = "参数解析错误";
        }
        if (data.returnCode == 204) {
          str = "扫描任务正在进行中，请稍后！";
        }
        if (!str) {
          str = "未知错误，请联系管理员";
        }
        this.$alert(`${str}！`, "提示", {
          confirmButtonText: "确定",
          callback: () => {},
        });
      }
    },
    // 断网重连，需要处理的逻辑
    reconnectWs() {
      if (this.wsObj) {
        this.createWs();
      }
    },
    // 以回调方式向服务端发送(对象类型的)心跳
    sendHeartBeat() {
      this.wsObj.send(JSON.stringify(this.sendObj));
    },
    sendMsg(val) {
      if (val == 1) {
        this.scanImgArr = [];
      }
      this.indexReq = val;
      let data = {
        examPaperId: this.$parent.currentExamPaper.id,
        subjectId: this.$route.query.subId,
      };
      this.loadingUploadExamPaperDialog = true;

      this.sendObj = {
        action: "startPaper",
        data: data,
      };
      this.clientLoading = true;
      websocketCommand(
        this.wsObj,
        "push",
        5000,
        this.sendHeartBeat,
        this.receiveMsg,
        this.reconnectWs,
        this.sendObj
      );
    },
    createWs() {
      websocketCommand(this.wsObj, "close");
      this.clientSideType = true;
      this.clientLoading = false;
      this.clientSideLinkType = false;
      this.btnDisabled = true;

      this.wsObj = new WebSocket("ws://127.0.0.1:9468/wtyscans");
      this.sendObj = {
        action: "communicationScan",
      };
      websocketCommand(
        this.wsObj,
        "create",
        5000,
        this.sendHeartBeat,
        this.receiveMsg,
        this.reconnectWs,
        this.sendObj
      );
    },
    capitalNumberFn(val) {
      return capitalNumberFn(val);
    },
    async rotatingImg(item, key) {
      let data = {
        url: item,
        degree: 90,
      };
      await rotateImage(data);
      this.scanImgArr = this.scanImgArr.map((index, i) => {
        if (key == i) {
          index = `${index}?time=${new Date().getTime()}`;
        }
        return index;
      });
    },
    async rotatingImgAll() {
      let arr = [];
      this.scanImgArr.map(async (item) => {
        let data = {
          url: item,
          degree: 90,
        };
        await rotateImage(data);
        item = `${item}?time=${new Date().getTime()}`;
        arr.push(item);
      });
      this.scanImgArr = arr;
    },
    delItem(item) {
      this.scanImgArr = this.scanImgArr.filter((index, i) => i != item);
    },
    getChangeLists() {
      // console.log(this.scanImgArr);
    },
    downClient() {
      downClient();
    },
    init() {
      this.initUploadExamPaperDialog();
      this.uploadExamPaperDialogVisible = true;
    },
    uploadExamPaperOnChange(file, fileList) {
      let existFile = fileList
        .slice(0, fileList.length - 1)
        .find((f) => f.name === file.name);
      if (existFile) {
        this.$message.error("当前文件已经存在！");
        fileList.pop();
      }
      let checkRes = this.checkFile(file);
      if (!checkRes) {
        fileList.pop();
      }
      this.uploadExamPaperFileList = fileList;
    },
    checkFile(file) {
      if (this.uploadExamPaperFileType === 1) {
        if (!file.name.endsWith(".docx")) {
          this.$message.error("只能上传 docx 文件！");
          return false;
        }
      } else if (this.uploadExamPaperFileType === 2) {
        if (!file.name.endsWith(".pdf") && !file.name.endsWith(".PDF")) {
          this.$message.error("只能上传 pdf 文件！");
          return false;
        }
      } else if (this.uploadExamPaperFileType === 3) {
        if (
          !file.name.endsWith(".jpg") &&
          !file.name.endsWith(".jpeg") &&
          !file.name.endsWith(".png") &&
          !file.name.endsWith(".bmp") &&
          !file.name.endsWith(".gif")
        ) {
          this.$message.error("只能上传图片文件！");
          return false;
        }
      }
      let is30M = file.size / 1024 / 1024 <= 30;
      if (!is30M) {
        this.$message.error("文件大小不能超过 30 MB！");
        return false;
      }
      return true;
    },
    uploadExamPaperBeforeRemove(file, fileList) {
      this.uploadExamPaperFileList = fileList;
    },
    uploadExamPaperOnExceed() {
      this.$message.warning(
        "最多只能上传 " + this.uploadExamPaperLimit + " 个文件！"
      );
    },
    uploadExamPaperHttpRequest(file) {
      this.uploadExamPaperFileData.append("file", file.file);
    },
    uploadexampaperByScan() {
      if (this.scanImgArr.length === 0) {
        this.$message({
          showClose: true,
          message: "请先扫描试卷！",
          type: "warning",
        });
        return;
      }
      let data = {
        examPaperId: this.$parent.currentExamPaper.id,
        fileType: 3,
        urls: this.scanImgArr.join(","),
      };
      this.loadingUploadExamPaperDialog = true;
      uploadexampaperByScan(data)
        .then(() => {
          this.$message.success("上传试卷成功！");
          this.initUploadExamPaperDialog();
          this.loadingUploadExamPaperDialog = false;
          this.uploadExamPaperDialogVisible = false;
          this.$parent.getExamPaperInfo(this.$parent.currentExamPaper.id);
        })
        .catch(() => {
          this.loadingUploadExamPaperDialog = false;
        });
    },
    uploadExamPaper() {
      if (this.uploadExamPaperFileList.length === 0) {
        return;
      }
      this.loadingUploadExamPaperDialog = true;
      this.uploadExamPaperFileData = new FormData();
      this.uploadExamPaperFileData.append("examId", this.$parent.examId);
      this.uploadExamPaperFileData.append(
        "subjectId",
        this.$parent.currentSubjectId
      );
      this.uploadExamPaperFileData.append(
        "examPaperId",
        this.$parent.currentExamPaper.id
      );
      this.uploadExamPaperFileData.append(
        "fileType",
        this.uploadExamPaperFileType
      );
      this.$refs.uploadExamPaper.submit();

      uploadExamPaper(this.uploadExamPaperFileData).then(() => {
        this.$message.success("上传试卷成功！");
        removeStore({
          name: this.$parent.storePrefix + this.$parent.currentExamPaper.id,
        });
        this.$parent.currentExamPaper = {
          id: this.$parent.currentExamPaper.id,
          resultPath: "",
        };
        this.$parent.examQuestions = {};
        this.$parent.examPaperQuestions = {};
        this.$parent.currentSubjectExamQuestions = [];
        this.uploadExamPaperDialogVisible = false;
        this.loadingUploadExamPaperDialog = false;
        this.initUploadExamPaperDialog();
        this.$parent.progressStep = 2;
      });
    },
    initUploadExamPaperDialog() {
      this.uploadExamPaperFileType = 2;
      this.uploadExamPaperFileData = new FormData();
      this.uploadExamPaperFileList = [];
      this.uploadExamPaperAccept = ".pdf,PDF";
      this.uploadExamPaperMultiple = false;
      this.uploadExamPaperLimit = 1;
      this.uploadExamPaperFileListEle = [];
    },
    changeUploadExamPaperFileType(fileType) {
      this.uploadExamPaperFileType = fileType;
      this.uploadExamPaperFileData = new FormData();
      this.uploadExamPaperFileList = [];
      if (this.uploadExamPaperFileType === 1) {
        this.uploadExamPaperAccept = ".docx";
        this.uploadExamPaperMultiple = false;
        this.uploadExamPaperLimit = 1;
      } else if (this.uploadExamPaperFileType === 2) {
        this.uploadExamPaperAccept = ".pdf";
        this.uploadExamPaperMultiple = false;
        this.uploadExamPaperLimit = 1;
      } else if (this.uploadExamPaperFileType === 3) {
        this.uploadExamPaperAccept = ".jpg,.jpeg,.png,.bmp,.gif";
        this.uploadExamPaperMultiple = true;
        this.uploadExamPaperLimit = 100;
      }
      this.uploadExamPaperFileListEle = [];
    },
  },
};
</script>
<style lang="scss">
.scan-item {
  width: 164px;
  margin-bottom: 12px;
  z-index: 9999;
  .index-nums {
    text-align: center;
  }
  .scan-item-img {
    padding: 4px;
    background-color: #f8f8f8;
    position: relative;
    width: 100%;
    height: 118px;
    overflow: hidden;

    .op-iocn {
      position: absolute;
      width: 18px;
      height: 18px;
      text-align: center;
      right: 0;
      bottom: 0;
      z-index: 1;
      line-height: 1;
      cursor: pointer;
      background: rgba(0, 0, 0, 0.6);
      img {
        width: 12px;
      }
    }
    .op-iocn-a {
      right: 24px;
    }
    img {
      width: 100%;
    }
  }
}
.upload-scan-item {
  border: 1px dashed #dee0e7;
  display: grid;
  grid-template-columns: repeat(3, 33.3%);
  max-height: 320px;
  padding: 12px;
}
</style>
<style lang="scss" scoped>
.uploadFile {
  ::v-deep .el-upload {
    display: block;
    .el-upload-dragger {
      width: 100%;
    }
  }
  .img-msg-ctn {
    background: #f7faff;
    padding: 10px;
    color: #3e4551;
    margin-top: 18px;
    font-size: 14px;
  }
  .succ {
    color: $primary-color;
    border-bottom: 1px solid $primary-color;
    cursor: pointer;
  }

  .err {
    color: #ff0000;
    margin-right: 24px;
  }
  .waring {
    color: #f0bd62;
  }
  .upload-scan-header {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
  }

  .upload-scan {
    border: 1px dashed #dee0e7;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .tips-scan {
      font-size: 14px;
      color: #83878f;
    }
  }
  ::v-deep {
    .upload-text {
      margin-top: 68px;
    }
  }
  .upload-exam-paper-dialog {
    .upload-exam-paper-dialog-header {
      margin-bottom: 18px;
      .filetype {
        display: inline-block;
        margin-right: 20px;
        cursor: pointer;
      }

      .selected-filetype {
        color: #2474ed;
      }
    }
  }
}
</style>
