<template>
  <el-dialog
    :title="userInfo.schoolVersion != 2 ? '下载原题 / 变式题' : '下载原题'"
    :visible.sync="visible"
    width="30%"
    :before-close="handleClose"
  >
    <el-form
      ref="form"
      label-position="right"
      label-width="100px"
      :model="form"
    >
      <el-form-item label="下载类型">
        <el-checkbox-group v-model="form.type">
          <el-checkbox label="1">原题</el-checkbox>
          <el-checkbox v-if="userInfo.schoolVersion != 2" label="2"
            >变式题</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="附带答案">
        <el-checkbox
          v-model="form.addAnswer"
          :true-label="1"
          :false-label="0"
          @change="addAnswerChange"
        ></el-checkbox>
      </el-form-item>
      <el-form-item v-if="form.addAnswer === 1" label="答案位置">
        <el-radio-group v-model="form.answerPosition">
          <el-radio :label="1">每道试题后面</el-radio>
          <el-radio :label="2">最后</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="文档格式">
        <el-radio-group v-model="form.fileType">
          <el-radio :label="3">PDF 文档</el-radio>
          <el-radio :label="1">Word 文档</el-radio>
          <el-radio :label="2">Word 97-2003 文档 </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" :loading="btnloading" @click="submit"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import {
  downloadexampaperquestion,
  // downloadexampaperquestionextend,
} from "@/core/api/exam/exampaper";
import { getStore } from "@/core/util/store";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      visible: true,
      form: {
        addAnswer: 0,
        answerPosition: null,
        fileType: 3,
        type: [],
      },
      btnloading: false,
    };
  },
  created() {
    this.userInfo = getStore({ name: "userInfo" });
    this.userInfo.schoolVersion != 2
      ? (this.form.type = ["1", "2"])
      : (this.form.type = ["1"]);
  },
  methods: {
    handleClose() {
      this.$parent.downloadExamPaperDialogVisible = false;
      this.form = {
        addAnswer: 0,
        answerPosition: null,
        fileType: 3,
      };
    },
    addAnswerChange() {
      if (!this.form.answerPosition) {
        this.form.answerPosition = 2;
      }
      if (this.form.addAnswer === 0) {
        this.form.answerPosition = null;
      }
    },
    async submit() {
      if (this.form.type.length == 0) {
        this.$message({
          showClose: true,
          message: "至少选择一种下载类型！",
          type: "warning",
        });
        return;
      }
      this.btnloading = true;
      let data = JSON.parse(JSON.stringify(this.form));
      data.type = data.type.join(",");
      // if (this.title === "下载原卷") {
      try {
        const res = await downloadexampaperquestion({
          id: this.$parent.currentExamPaper.id,
          ...data,
        });
        const { url, name } = res.data.data;
        const { href } = this.$router.resolve({
          path: "/downFile",
          query: {
            url: url,
            name: name,
            type: 1,
          },
        });
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          clearTimeout(timer);
        }, 0);
        this.btnloading = false;
      } catch {
        this.btnloading = false;
      }
      // } else if (this.title === "下载变式题") {
      //   try {
      //     const res = await downloadexampaperquestionextend({
      //       id: this.$parent.currentExamPaper.id,
      //       ...this.form,
      //     });
      //     const { url, name } = res.data.data;
      //     const { href } = this.$router.resolve({
      //       path: "/downFile",
      //       query: {
      //         url: url,
      //         name: name,
      //         type: 1,
      //       },
      //     });
      //     let timer = setTimeout(() => {
      //       window.open(href, "_blank");
      //       clearTimeout(timer);
      //     }, 0);
      //     this.btnloading = false;
      //   } catch {
      //     this.btnloading = false;
      //   }
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-radio {
  margin-bottom: 0;
}
</style>
