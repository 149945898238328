<template>
  <div class="examinationQuestionNotice">
    <el-dialog
      title="推送审题通知"
      :visible.sync="dialogVisible"
      width="402px"
      :close-on-click-modal="false"
    >
      <div class="top-tips">
        <el-radio v-model="pushType" label="1">配题完成通知</el-radio>
        <el-radio v-model="pushType" label="2">配题修改通知</el-radio>
        <el-tooltip class="item" effect="dark" placement="right">
          <template v-if="pushType == 1">
            <div v-if="trainingTimeEffective == 1" slot="content">
              老师，你好！<br />XX场考试同类题配题工作已经完成，需要您在2012年3月2日<br />12:23:59~2012年3月3日12:23:59期间，协助完成配题审核工<br />作，万分感谢！
            </div>
            <div v-if="trainingTimeEffective == 0" slot="content">
              老师，你好！<br />XX场考试同类题配题工作已经完成，需要您尽快协助完成配<br />题审核工作，万分感谢！
            </div>
          </template>

          <template v-else>
            <div slot="content">
              老师，你好！<br />XX场考试XX题、XX题同类题配题发生变动，需要您尽快协助<br />完成配题审核工作，万分感谢！
            </div>
          </template>
          <el-button type="text" icon="el-icon-question">
            推送通知样例
          </el-button>
        </el-tooltip>
      </div>

      <el-form
        ref="dynamicValidateForm"
        :model="dynamicValidateForm"
        label-width=""
        :rules="rules"
      >
        <div class="check-teacher">
          <div class="check-teacher-header">
            <el-checkbox v-model="checkAll" @change="checkAllFn()"
              >全选</el-checkbox
            >
          </div>

          <div class="teacher-box edit-scroll-style">
            <el-form-item
              v-for="(item, index) in dynamicValidateForm.teacherList"
              :key="index"
              :prop="'teacherList.' + index + '.phone'"
            >
              <el-checkbox
                v-model="item.check"
                @change="checkChange('teacherList.' + index + '.phone')"
                >{{ item.teacherName }}</el-checkbox
              >
              <el-input
                v-model="item.phone"
                :disabled="!item.check"
                placeholder="请填写联系方式"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <el-form-item
          label="回复手机号："
          prop="replyPhone"
          class="phone-box is-required"
        >
          <el-input
            v-model.number="dynamicValidateForm.replyPhone"
            autocomplete="off"
            placeholder="请填写联系方式"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submitForm()">
          推送通知
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { completematchPush } from "@/core/api/exam/exampaper";
export default {
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      pushType: "1",
      checkAll: false,
      trainingTimeEffective: 1,
      rules: {
        replyPhone: [
          {
            validator: this.validatePhone,
            trigger: "blur",
          },
        ],
      },
      dynamicValidateForm: { teacherList: [], replyPhone: "" },
    };
  },
  created() {},
  methods: {
    checkAllFn() {
      this.dynamicValidateForm.teacherList =
        this.dynamicValidateForm.teacherList.map((item) => {
          if (this.checkAll == false) {
            item.check = false;
          } else {
            item.check = true;
          }
          return item;
        });
      this.dynamicValidateForm = JSON.parse(
        JSON.stringify(this.dynamicValidateForm)
      );
      // console.log(JSON.parse(JSON.stringify(this.dynamicValidateForm)));
      this.dynamicValidateForm.teacherList.map((item, index) => {
        this.checkChange("teacherList." + index + ".phone");
      });
    },
    validatePhone(rule, value, callback) {
      if (value === "") {
        callback(new Error("请填写联系方式"));
      } else {
        const reg = /^1[3-9]\d{9}$/;
        if (reg.test(value)) {
          // 手机号格式正确
          callback();
        } else {
          // 手机号格式错误
          callback(new Error("请填写正确的联系方式"));
        }
      }
    },
    submitForm() {
      this.$refs.dynamicValidateForm.validate((valid) => {
        if (valid) {
          let array = this.dynamicValidateForm.teacherList.filter(
            (item) => item.check
          );
          if (array.length == 0) {
            this.$message({
              showClose: true,
              message: "请勾选需要推送通知教师！",
              type: "error",
            });
            return;
          }
          this.submitList(array);
        } else {
          return false;
        }
      });
    },
    submitList(array) {
      let paperId = this.$parent.examPapers.filter(
        (item) => item.subjectId == this.$parent.currentSubjectId
      )[0].id;

      let data = {
        replyPhone: this.dynamicValidateForm.replyPhone,
        type: this.pushType,
        examPaperTeacherReviewSimpleDTOS: array,
        examPaperId: paperId,
      };

      this.btnLoading = true;
      completematchPush(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "通知已推送成功！",
            type: "success",
          });
          this.btnLoading = false;
          this.dialogVisible = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },

    checkChange(key) {
      this.$refs.dynamicValidateForm.validateField(key);
      let arr = this.dynamicValidateForm.teacherList.filter(
        (item) => item.check
      );
      if (arr.length == this.dynamicValidateForm.teacherList.length) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
    },
    validatePassword(rule, value, callback) {
      let { field } = rule;
      let num = field.split(".")[1];
      num = Number(num);
      if (this.dynamicValidateForm.teacherList[num].check) {
        if (this.dynamicValidateForm.teacherList[num].phone) {
          const reg = /^1[3-9]\d{9}$/;
          if (reg.test(this.dynamicValidateForm.teacherList[num].phone)) {
            callback();
          } else {
            callback(new Error("请填写正确的联系方式"));
          }
        } else {
          callback(new Error("请填写联系方式"));
        }
      } else {
        callback();
      }
    },
    init() {
      this.checkAll = false;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.dynamicValidateForm.resetFields();
        if (this.$parent.indexPaper.trainingTimeEffective == 1) {
          this.trainingTimeEffective = 1;
        } else {
          this.trainingTimeEffective = 0;
        }
        this.dynamicValidateForm.teacherList =
          this.$parent.paperTeacherList.map((item, index) => {
            this.rules["teacherList." + index + ".phone"] = [
              { validator: this.validatePassword, trigger: ["blur", "change"] },
            ];
            return {
              teacherName: item.teacherName,
              check: false,
              phone: item.phone,
              teacherId: item.teacherId,
              authority: item.authority,
            };
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.examinationQuestionNotice {
  .phone-box {
    display: flex;
    margin-top: 12px;
    margin-bottom: 0;
    ::v-deep {
      .el-form-item__error {
        margin-top: -8px;
      }
    }
    .el-input {
      width: 240px;
    }
  }
  .check-teacher {
    border: 1px solid #dee0e7;
    .teacher-box {
      max-height: 260px;
      padding: 24px;
      .el-checkbox {
        margin-bottom: 0;
      }
    }
    .check-teacher-header {
      border-bottom: 1px solid #dee0e7;
      padding: 12px 16px;
      background: #f5f7fa;
      .el-checkbox {
        margin-bottom: 0;
      }
    }
  }
  .top-tips {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 12px;
    .el-radio {
      margin-right: 0;
    }
  }
}
</style>
