<template>
  <div v-loading="loading" class="exam-paper">
    <!-- <div class="paper-title">
      <el-form>
        <i class="el-icon-back" @click="back" />
        <el-form-item :label="examName" :class="selfpaperlabel" />
      </el-form>
    </div> -->
    <detailsHeader
      ref="detailsHeader"
      router-url="examlist"
      :query="{
        examId: $route.query.id,
      }"
    ></detailsHeader>

    <div class="paper-content">
      <div class="paper-content-header">
        <el-menu
          :default-active="currentSubjectId.toString()"
          class="el-menu-demo"
          mode="horizontal"
          active-text-color="#2474ED"
          @select="handleSubjectSelect"
        >
          <!-- {{ examPapers }} -->
          <el-menu-item
            v-for="subject in examPapers"
            :key="subject.id"
            :index="subject.subjectId.toString()"
            :disabled="loadingExamPaper"
            >{{ subject.subjectName }}</el-menu-item
          >
        </el-menu>
        <div class="btn-box">
          <el-button
            v-if="statusUpload !== 0"
            :loading="downloadBtn"
            @click="downLoadgetexampaperzip"
          >
            <img src="@/assets/newExam/newpaper/icon_down.png" alt="" />
            <img
              class="on"
              src="@/assets/newExam/newpaper/icon_down_on.png"
              alt=""
            />
            下载原卷
          </el-button>
          <el-button
            v-if="userInfo.schoolVersion != 2"
            @click="downloadExamPaper('origin')"
          >
            <img src="@/assets/newExam/newpaper/icon_down.png" alt="" />
            <img
              class="on"
              src="@/assets/newExam/newpaper/icon_down_on.png"
              alt=""
            />
            下载原题 / 变式题
          </el-button>
          <el-button v-else @click="downloadExamPaper('origin')">
            <img src="@/assets/newExam/newpaper/icon_down.png" alt="" />
            <img
              class="on"
              src="@/assets/newExam/newpaper/icon_down_on.png"
              alt=""
            />
            下载原题
          </el-button>
          <!--         -->
          <el-button
            v-if="!$route.query.ifVirtual"
            @click="combinatorialQuestionOrder"
          >
            <img src="@/assets/newExam/newpaper/icon_group.png" alt="" />
            <img
              class="on"
              src="@/assets/newExam/newpaper/icon_group_on.png"
              alt=""
            />
            组合题号
          </el-button>
          <el-button @click="clickCopyExamPaper">
            <img src="@/assets/newExam/newpaper/icon_copy.png" alt="" />
            <img
              class="on"
              src="@/assets/newExam/newpaper/icon_copy_on.png"
              alt=""
            />
            复制其他考试试卷
          </el-button>
        </div>
      </div>
      <div v-loading="loadingExamPaper" class="paper-content-body">
        <div class="paper-content-box">
          <div class="paper-content-body-header">
            <div class="paper-progress-bar">
              <div class="paper-progress" @click="clickStep(1)">
                <template v-if="statusUpload === 0">
                  <div class="paper-progress-num">1</div>
                </template>
                <template v-else>
                  <div class="paper-progress-num progress-finish">
                    <i class="el-icon-check"></i>
                  </div>
                </template>
                <div class="paper-progress-title">上传试卷</div>
                <div class="tips-box">
                  <p v-if="statusUpload === 0">等待上传试卷</p>
                  <p v-else class="succ">完成上传试卷</p>
                </div>
              </div>
              <div class="sepaline"></div>
              <div class="paper-progress" @click="clickStep(2)">
                <template v-if="statusCut === 0">
                  <div class="paper-progress-num">2</div>
                </template>
                <template v-else>
                  <div class="paper-progress-num progress-finish">
                    <i class="el-icon-check"></i>
                  </div>
                </template>
                <div class="paper-progress-title">裁切试题/设置答案</div>
                <div class="tips-box">
                  <p v-if="statusCut === 0">等待裁切试卷</p>
                  <p v-else class="succ">完成裁切试题</p>
                  <p v-if="currentExamPaper.statusPaperAnswer == 0">
                    等待设置答案
                  </p>
                  <p
                    v-if="currentExamPaper.statusPaperAnswer == 1"
                    class="succ"
                  >
                    完成设置答案
                  </p>
                  <p v-if="currentExamPaper.statusPaperAnswer == 2">
                    部分答案已设置
                  </p>
                </div>
              </div>
              <template v-if="userInfo.schoolVersion != 2">
                <div class="sepaline"></div>
                <!-- knowledgeType: false, -->
                <!-- trainingType: false, -->
                <!-- userInfo.schoolVersion == 2 -->
                <div
                  class="paper-progress"
                  :class="{ voluntarily: knowledgeType }"
                  @click="clickStep(3)"
                >
                  <template v-if="statusKnowledge === 0">
                    <div class="paper-progress-num">3</div>
                  </template>
                  <template v-else>
                    <div class="paper-progress-num progress-finish">
                      <i class="el-icon-check"></i>
                    </div>
                  </template>
                  <div class="paper-progress-title">标注知识点</div>
                  <div class="tips-box">
                    <p v-if="statusKnowledge === 0">等待标记知识点</p>
                    <template v-else>
                      <p v-if="knowledgeType" class="tips-err">
                        系统标注知识点
                      </p>
                      <p v-else class="succ">完成标记知识点</p>
                    </template>
                  </div>
                </div>
                <div class="sepaline"></div>

                <div
                  class="paper-progress"
                  :class="{ voluntarily: trainingType }"
                  @click="clickStep(4)"
                >
                  <template v-if="indexPaper.trainingMateStatus === 0">
                    <div class="paper-progress-num">4</div>
                  </template>
                  <template v-else>
                    <div class="paper-progress-num progress-finish">
                      <i class="el-icon-check"></i>
                    </div>
                  </template>
                  <div class="paper-progress-title">匹配变式题</div>
                  <div class="tips-box">
                    <!-- {{ indexPaper.trainingMateStatus }} -->
                    <p v-if="indexPaper.trainingMateStatus === 0">
                      尚未匹配变式题
                    </p>
                    <template v-else>
                      <p v-if="trainingType" class="tips-err">系统匹配变式题</p>
                      <p v-else class="succ">完成匹配变式题</p>
                      <p
                        v-if="indexPaper.trainingTeacherStatus > 0"
                        class="succ"
                      >
                        完成设置审题教师
                      </p>
                      <p v-else>待设置审题教师</p>
                      <!-- ${paperAnalyze.reviewed}题/共${paperAnalyze.total}题` -->
                      <p
                        v-if="indexPaper.trainingQuestionStatus > 0"
                        class="succ"
                      >
                        完成审题
                      </p>
                      <p v-else>待审题</p>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="paper-progress-button">
            <template v-if="progressStep === 1">
              <el-button type="primary" @click="clickProgressStepButton">
                上传试卷
              </el-button>
            </template>
            <template v-else-if="progressStep === 2">
              <el-button type="primary" @click="clickProgressStepButton">
                裁切试题
              </el-button>
              <el-tooltip
                v-if="statusCut === 0"
                class="item"
                effect="dark"
                content="请先完成裁切试题"
                placement="top-start"
              >
                <el-button type="primary" class="dis-btn"> 设置答案 </el-button>
              </el-tooltip>
              <el-button v-else type="primary" @click="setAnswer()">
                设置答案
              </el-button>
            </template>
            <template v-if="progressStep === 3 && userInfo.schoolVersion != 2">
              <el-button type="primary" @click="clickProgressStepButton">
                标注知识点
              </el-button>
            </template>
            <template v-if="progressStep === 4 && userInfo.schoolVersion != 2">
              <template v-if="paperTeacherList.length == 0">
                <el-button type="primary" @click="clickProgressStepButton">
                  匹配变式题
                </el-button>
                <el-button
                  v-if="indexPaper.trainingMateStatus"
                  type="primary"
                  @click="setExamineTeacher"
                >
                  设置审核教师
                </el-button>
                <el-tooltip
                  v-else
                  class="item"
                  effect="dark"
                  content="请先完成匹配变式题"
                  placement="top"
                >
                  <span class="btn-style">设置审核教师</span>
                </el-tooltip>
              </template>
              <template v-else>
                <el-popover placement="bottom" trigger="hover">
                  <div class="popover-box">
                    <div class="popover-item">
                      审核进度：{{
                        `${paperAnalyze.reviewed}题/共${paperAnalyze.total}题`
                      }}
                    </div>
                    <div class="popover-item">审核时间：{{ getTimeVal() }}</div>
                  </div>
                  <el-button-group slot="reference">
                    <el-button type="primary" @click="clickProgressStepButton">
                      匹配变式题
                    </el-button>
                    <el-button type="primary" @click="clickProgressStepButton">
                      <el-progress
                        type="circle"
                        color="#ffffff"
                        :show-text="false"
                        :percentage="
                          (paperAnalyze.reviewed / paperAnalyze.total) * 100
                        "
                      ></el-progress>
                      {{
                        paperAnalyze.total == 0
                          ? 0
                          : paperAnalyze.total == paperAnalyze.reviewed
                          ? 100
                          : (
                              (paperAnalyze.reviewed / paperAnalyze.total) *
                              100
                            ).toFixed(2)
                      }}%
                    </el-button>
                  </el-button-group>
                </el-popover>
                <el-popover placement="bottom" trigger="hover">
                  <div class="popover-box">
                    <div>审核教师：共{{ paperTeacherList.length }}人</div>
                    <div class="popover-box-item edit-scroll-style">
                      <div
                        v-for="(item, index) in paperTeacherList"
                        :key="index"
                        class="popover-item"
                      >
                        <span> {{ item.teacherName }}</span>
                        <span>{{ getName(item) }}</span>
                      </div>
                    </div>
                  </div>

                  <el-button-group
                    v-if="roleId != 6"
                    slot="reference"
                    style="margin-left: 12px"
                  >
                    <el-button type="primary" @click="setExamineTeacher">
                      设置审核教师
                    </el-button>
                    <el-button type="primary" @click="setExamineTeacher">
                      {{ paperTeacherList.length }}人
                    </el-button>
                  </el-button-group>
                </el-popover>
                <el-button
                  type="primary"
                  style="margin-left: 12px; vertical-align: middle"
                  @click="showPush()"
                  >推送审题通知</el-button
                >
              </template>
            </template>
          </div>
        </div>

        <div
          v-if="currentSubjectExamQuestions.length > 0"
          class="paper-content-main"
        >
          <template v-for="(question, index) in currentSubjectExamQuestions">
            <div :key="index" class="paper-question">
              <div class="paper-question-header">
                <div class="paper-question-num" :title="question.examPaperNum">
                  {{ question.examPaperNum }}
                </div>
                <div class="paper-question-type">{{ question.typeName }}</div>
                <div class="paper-question-difficulty">
                  {{ displayDifficulty(question.difficulty) }}
                </div>
              </div>
              <div
                class="paper-question-content"
                :class="{ isCollapse: question.isCollapse === 1 }"
                @click="clickExamQuestionCollapseIcon(question, true)"
              >
                <div v-html="question.question"></div>
              </div>
              <div class="paper-question-footer">
                <div class="paper-question-knowledge">
                  <div
                    v-if="question.knowledgeArray.length > 0"
                    class="paper-question-knowledge-title"
                  >
                    知识点：
                  </div>

                  <div
                    v-for="(knowledge, kIndex) in question.knowledgeArray"
                    :key="kIndex"
                    class="paper-question-knowledge-name"
                    :title="knowledge"
                  >
                    {{ knowledge }}
                  </div>
                </div>
                <div class="paper-question-action-bar">
                  <div
                    class="paper-question-collapse-icon"
                    @click="clickExamQuestionCollapseIcon(question)"
                  >
                    <template v-if="question.isCollapse === 1">
                      <i class="el-icon-caret-bottom" title="展开"></i>
                    </template>
                    <template v-else-if="question.isCollapse === 0">
                      <i class="el-icon-caret-top" title="收起"></i>
                    </template>
                  </div>
                  <div
                    class="paper-question-action"
                    @click="clickDisplayQuestionVar(question, index)"
                  >
                    <template v-if="question.displayOpt === 1">
                      隐藏同类题
                    </template>
                    <template v-else-if="question.displayOpt === 0">
                      显示同类题
                    </template>
                  </div>
                  <div class="paper-question-action-sepaline">|</div>
                  <div
                    class="paper-question-action"
                    @click="clickAnswerAnalysisButton(question)"
                  >
                    答案解析
                  </div>
                  <div class="paper-question-action-sepaline">|</div>
                  <!--<div-->
                  <!--class="paper-question-action"-->
                  <!--@click="clickEditQuestion(question)"-->
                  <!--&gt;-->
                  <!--编辑试题-->
                  <!--</div>-->
                  <!--<div class="paper-question-action-sepaline">|</div>-->
                  <div
                    class="paper-question-action"
                    @click="clickEditQuestionNum(question)"
                  >
                    编辑题号
                  </div>
                </div>
              </div>
              <div
                v-if="question.isCollapseAnswer === 0"
                class="paper-question-below"
              >
                <div
                  v-if="question.answer && question.answer.length > 0"
                  class="below-item"
                >
                  【答案】
                  <div v-html="question.answer"></div>
                </div>
                <div v-else class="below-item">未设置答案</div>
                <div
                  v-if="question.analysis && question.analysis.length > 0"
                  class="below-item"
                >
                  【解答】
                  <div v-html="question.analysis"></div>
                </div>
                <div
                  v-if="question.analyse && question.analyse.length > 0"
                  class="below-item"
                >
                  【分析】
                  <div v-html="question.analyse"></div>
                </div>
                <div
                  v-if="question.ability && question.ability.length > 0"
                  class="below-item"
                >
                  【点评】
                  <div v-html="question.ability"></div>
                </div>
              </div>
            </div>
            <template
              v-for="(questionOpt, optIndex) in question.examQuestionOpts"
            >
              <div
                v-if="question.display == 1"
                :key="index + '-' + optIndex"
                class="paper-question"
              >
                <div
                  v-if="questionOpt.difficultyType"
                  class="paper-question-header"
                >
                  <div class="paper-question-var-num">
                    拓展题{{ questionOpt.qOptNum }}
                  </div>

                  <div class="paper-question-difficulty">
                    <template v-if="questionOpt.difficultyType == 1">
                      较难
                    </template>
                    <template v-if="questionOpt.difficultyType == 2">
                      中等
                    </template>
                    <template v-if="questionOpt.difficultyType == 3">
                      较易
                    </template>
                  </div>
                </div>
                <div v-else class="paper-question-header">
                  <div
                    class="paper-question-var-num"
                    :title="'同类题套' + optIndex + 1"
                  >
                    套{{ optIndex + 1 }}
                  </div>
                  <!-- <div class="paper-question-type">
                    {{ questionOpt.typeName }}
                  </div> -->
                  <div class="paper-question-difficulty">
                    {{ displayDifficulty(questionOpt.difficulty) }}
                  </div>
                </div>
                <div
                  class="paper-question-content"
                  :class="{ isCollapse: questionOpt.isCollapse === 1 }"
                  @click="clickExamQuestionCollapseIcon(questionOpt, true)"
                >
                  <div v-html="questionOpt.question"></div>
                </div>
                <div class="paper-question-footer">
                  <div class="paper-question-knowledge">
                    <div
                      v-if="
                        questionOpt.knowledgeArray &&
                        questionOpt.knowledgeArray.length > 0
                      "
                      class="paper-question-knowledge-title"
                    >
                      知识点：
                    </div>

                    <div
                      v-for="(knowledge, kIndex) in questionOpt.knowledgeArray"
                      :key="kIndex"
                      class="paper-question-knowledge-name"
                      :title="knowledge"
                    >
                      {{ knowledge }}
                    </div>
                  </div>
                  <div class="paper-question-action-bar">
                    <div
                      class="paper-question-collapse-icon"
                      @click="clickExamQuestionCollapseIcon(questionOpt)"
                    >
                      <template v-if="questionOpt.isCollapse === 1">
                        <i class="el-icon-caret-bottom" title="展开"></i>
                      </template>
                      <template v-else-if="questionOpt.isCollapse === 0">
                        <i class="el-icon-caret-top" title="收起"></i>
                      </template>
                    </div>
                    <!-- {{ questionOpt.isCollapseAnswer }} -->
                    <div
                      class="paper-question-action"
                      @click="clickAnswerOpt(index, optIndex)"
                    >
                      答案解析
                    </div>
                  </div>
                </div>

                <div
                  v-if="questionOpt.isCollapseAnswer"
                  class="paper-question-below"
                >
                  <div
                    v-if="questionOpt.answer && questionOpt.answer.length > 0"
                    class="below-item"
                  >
                    【答案】
                    <div v-html="questionOpt.answer"></div>
                  </div>
                  <div
                    v-if="
                      questionOpt.analysis && questionOpt.analysis.length > 0
                    "
                    class="below-item"
                  >
                    【解答】
                    <div v-html="questionOpt.analysis"></div>
                  </div>
                  <div
                    v-if="questionOpt.analyse && questionOpt.analyse.length > 0"
                    class="below-item"
                  >
                    【分析】
                    <div v-html="questionOpt.analyse"></div>
                  </div>
                  <div
                    v-if="questionOpt.ability && questionOpt.ability.length > 0"
                    class="below-item"
                  >
                    【点评】
                    <div v-html="questionOpt.ability"></div>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>

    <!--编辑试题弹窗-->
    <el-dialog
      title="编辑试题"
      width="800px"
      :visible.sync="editExamQuestionDialogVisible"
      :lock-scroll="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div id="CKEditorToolbar"></div>
      <ckeditor
        v-model="editorData"
        class="editExamQuestionCKEditor"
        type="inline"
        :config="editorConfig"
        @ready="editorReady"
      ></ckeditor>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelButton" @click="cancelEditExamQuestionDialog"
          >取 消</el-button
        >
        <el-button class="confirmButton" @click="confirmEditExamQuestionDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!--修改试题编号弹窗-->
    <el-dialog
      title="修改试题编号"
      width="350px"
      :visible.sync="editExamQuestionNumDialogVisible"
      :lock-scroll="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <el-input v-model="examQuestionNum" placeholder="请输入内容"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="cancelButton"
          @click="editExamQuestionNumDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="confirmButton"
          @click="confirmEditExamQuestionNumDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <combinationQuestion ref="combinationQuestion"></combinationQuestion>
    <copyExam ref="copyExam"></copyExam>
    <setQuestionTc ref="setQuestionTc"></setQuestionTc>
    <uploadFile ref="uploadFile"></uploadFile>
    <examinationQuestionNotice
      ref="examinationQuestionNotice"
    ></examinationQuestionNotice>
    <downloadExamPaper v-if="downloadExamPaperDialogVisible" />
  </div>
</template>

<script>
import {
  getExamInfo,
  getExamPaperInfo,
  getExamPaper,
  reconvert,
  saveEditedExamQuestion,
  saveEditedExamQuestionNum,
  getTeacherList,
  saveTeacher,
  deleteTeacher,
  getexampaperzip,
} from "@/core/api/exam/exampaper";
// import { fetchList } from "@/core/api/exam/exam";
import downloadExamPaper from "../component/downloadExamPaper.vue";
import { getStore } from "@/core/util/store";
import $ from "jquery";
import CKEditor from "ckeditor4-vue";

import { getSubjectList } from "@/core/util/util";
import {
  // trainings,
  exampaperteacherreviewList,
  trainingExamine,
  getExampaper,
} from "@/core/api/exam/examLore";

import { schoollistbyexamid } from "@/core/api/exam/exam";
import combinationQuestion from "../component/combinationQuestion.vue";
import copyExam from "./component/copyExam.vue";
import uploadFile from "./component/uploadFile.vue";
import setQuestionTc from "./component/setQuestionTc.vue";
import detailsHeader from "@/components/detailsHeader.vue";
import examinationQuestionNotice from "./component/examinationQuestionNotice.vue";
import moment from "moment";
export default {
  components: {
    ckeditor: CKEditor.component,
    combinationQuestion: combinationQuestion,
    downloadExamPaper,
    copyExam,
    detailsHeader,
    setQuestionTc,
    uploadFile,
    examinationQuestionNotice,
  },
  beforeRouteEnter(to, from, next) {
    // console.log(from);path
    if (
      from.path == "/exam/exam" ||
      from.path == "/exam/unionExam" ||
      from.path == "/academic/statistics"
    ) {
      next(() => {
        localStorage.setItem("routerFrom", from.path);
      });
    } else {
      next();
    }
  },
  data() {
    const { roleId } = JSON.parse(
      localStorage.getItem("jzjx-userInfo")
    ).content;
    const nowYear = new Date().getFullYear();
    const arr = new Array(nowYear - 2014).fill("");
    let yearOptions = arr.map((item, index) => {
      return { label: index + 2015, value: index + 2015 };
    });
    return {
      examineFormRules: {
        cutOffTime: [
          { required: true, message: "请设置审核截止时间", tigger: "blur" },
        ],
      },
      examineDialogVisible: false,
      yearOptions: yearOptions.reverse(),
      examineForm: {},
      selfpaperlabel: "self-paper-label",
      selfprocessbutton: "self-process-button",
      examId: 0,
      examName: "",
      examLevel: 0,
      currentExamPaper: {},
      currentSubjectId: 0,
      currentSubjectExamQuestions: [],
      exam: {},
      examPapers: {},
      examQuestions: {},
      examPaperQuestions: {},
      progressStep: 1, // 当前进行的步骤 1-上传试卷 2-裁切试题 3-标注知识点 4-匹配变式题 5-已完成所有步骤
      statusUpload: 0, // 上传试卷状态
      statusCut: 0, // 裁切试题状态
      statusKnowledge: 0, // 标注知识点状态
      statusTraining: 0, // 匹配变式题状态
      statusReplace: 0, // 换题（试题电子化）状态
      loading: false,
      loadingExamPaper: false,
      subjectName: {
        1: "语文",
        2: "数学",
        3: "英语",
        4: "历史",
        5: "地理",
        6: "政治",
        7: "生物",
        8: "物理",
        9: "化学",
        10: "科学",
        11: "道德与法治",
      },
      storePrefix: "examPaperDivide-", // 试卷切题数据的本地存储前缀
      displayAllQuestionVar: 0, // 所有原题是否显示变式题
      editExamQuestionDialogVisible: false, // 编辑试题弹窗
      editExamQuestionNumDialogVisible: false, // 修改试题编号弹窗
      editingExamQuestion: {}, // 正在编辑的试题
      examQuestionNum: "",
      CKEditorName: "",
      editorData: "",
      editorConfig: {
        toolbar: [
          {
            name: "clipboard",
            items: ["Undo", "Redo"],
          },
          { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
          {
            name: "basicstyles",
            items: [
              "Bold",
              "Italic",
              "Underline",
              "Strike",
              "Subscript",
              "Superscript",
            ],
          },
          { name: "colors", items: ["TextColor", "BGColor"] },
          "/",
          {
            name: "format",
            items: ["CopyFormatting", "RemoveFormat"],
          },
          {
            name: "paragraph",
            items: [
              "NumberedList",
              "BulletedList",
              "-",
              "Outdent",
              "Indent",
              "-",
              "JustifyLeft",
              "JustifyCenter",
              "JustifyRight",
              "JustifyBlock",
            ],
          },
          {
            name: "insert",
            items: ["EasyImageUpload", "Table", "SpecialChar"],
          },
        ],
        removeButtons: "",
        extraPlugins:
          "sharedspace,font,colorbutton,copyformatting,justify,easyimage",
        sharedSpaces: {
          top: "CKEditorToolbar",
        },
        title: false,
        language: "zh-cn",
        allowedContent: true,
        extraAllowedContent: "div[onselectstart]",
        protectedSource: [/<a[^>]*><\/a>/g],
        readOnly: false,
        on: {
          dataReady: this.dataReady,
        },
      },

      levelOptions: [
        { label: "初中", value: 2 },
        { label: "高中", value: 3 },
      ],
      schoolList: [],
      roleId: roleId,
      examSubjectList: [],
      checkList: [],
      teacherList: [],
      examInformation: {},
      teacherLoading: false,
      saveTeacherLoading: false,
      downloadBtn: false,
      teacherId: null,
      id: null,
      downloadExamPaperTitle: null,
      downloadExamPaperDialogVisible: false,
      knowledgeType: false,
      trainingType: false,
      paperTeacherList: [],
      indexPaper: {},
      paperAnalyze: {},
    };
  },
  mounted() {
    window.MathJax.typesetPromise();
  },
  updated() {
    window.MathJax.startup.defaultReady();
    this.$nextTick(() => {
      this.initImg();
    });
  },
  created() {
    this.subjectOptions = getSubjectList();
    if (!this.$route.query.id || parseInt(this.$route.query.id || 0) <= 0) {
      return;
    }
    this.examId = parseInt(this.$route.query.id || 0);
    this.currentSubjectId = parseInt(this.$route.query.subId || 0);
    this.getExamInfo(this.examId);
    this.initCKEditor();
    this.userInfo = getStore({ name: "userInfo" });
  },
  destroyed() {
    sessionStorage.removeItem("routerFrom");
  },
  methods: {
    showPush() {
      this.$refs.examinationQuestionNotice.init();
    },
    getTimeVal() {
      let { trainingTimeBegin, trainingTimeEnd, trainingTimeEffective } =
        this.indexPaper;
      if (!trainingTimeEffective) {
        return "不限时";
      }
      if (!trainingTimeBegin && !trainingTimeEnd) {
        return "-";
      }

      trainingTimeEnd = moment(trainingTimeEnd).format("YYYY-MM-DD HH:mm");
      trainingTimeBegin = moment(trainingTimeBegin).format("YYYY-MM-DD HH:mm");
      return trainingTimeBegin + " ~ " + trainingTimeEnd;
    },
    getName(item) {
      if (item.authority == 0) {
        return "同类题&分层拓展题";
      }
      if (item.authority == 1) {
        return "同类题";
      }
      if (item.authority == 2) {
        return "分层拓展题";
      }
    },
    downloadExamPaper() {
      // type
      // type: 'origin' 下载原卷 'variant' 下载变式题
      // this.downloadExamPaperTitle =
      //   type === "origin" ? "下载原卷" : "下载变式题";
      this.downloadExamPaperDialogVisible = true;
    },
    getexampaperteacherreviewList(examPaperId) {
      let data = { examPaperId: examPaperId };
      exampaperteacherreviewList(data).then((res) => {
        this.paperTeacherList = res.data.data;
      });
    },
    gettrainingExamine(examPaperId) {
      let data = { examPaperId: examPaperId };
      trainingExamine(data).then((res) => {
        this.paperAnalyze = res.data.data;
        this.paperTeacherList = res.data.data.list;
      });
    },
    getGetExampaper(examPaperId) {
      getExampaper(examPaperId).then((res) => {
        this.indexPaper = res.data.data;
        if (this.indexPaper.trainingMateStatus > 0) {
          this.progressStep = 4;
        }
      });
    },
    combinatorialQuestionOrder() {
      let paperId = this.examPapers.filter(
        (item) => item.subjectId == this.currentSubjectId
      )[0].id;

      this.$refs.combinationQuestion.combinatorialQuestionOrder(paperId);
    },
    saveExamineTeacher() {
      this.$refs.examineForm.validate(async (valid) => {
        if (valid) {
          this.saveTeacherLoading = true;
          if (this.checkList.length) {
            try {
              await saveTeacher({
                teacherId: this.checkList.map((item) => item.id).toString(),
                examId: this.$route.query.id,
                subjectId: this.$route.query.subId,
                cutOffTime: this.examineForm.cutOffTime,
              });
              this.saveTeacherLoading = false;
              this.examineDialogVisible = false;
              this.$message.success("保存成功");
              this.$refs.examineForm.clearValidate();
              this.checkList = [];
            } catch {
              this.saveTeacherLoading = false;
              this.examineDialogVisible = false;
              this.$refs.examineForm.clearValidate();
            }
          } else {
            try {
              await deleteTeacher(this.id);
              this.saveTeacherLoading = false;
              this.examineDialogVisible = false;
              // this.$message.success("清空成功");
              this.checkList = [];
              this.$refs.examineForm.clearValidate();
            } catch {
              this.saveTeacherLoading = false;
              this.examineDialogVisible = false;
              this.$refs.examineForm.clearValidate();
            }
          }
        }
      });
    },
    setExamineTeacher() {
      this.$refs.setQuestionTc.init();
      // if (this.roleId === 6) {
      //   this.getUnionSchoolList();
      // }
      // const { id, subId } = this.$route.query;
      // getExampaperteacherreview({ examId: id, subjectId: subId }).then(
      //   (res) => {
      //     if (res.data.data.cutOffTime) {
      //       this.examineForm.cutOffTime = res.data.data.cutOffTime;
      //     }
      //     this.teacherId = res.data.data.teacherId;
      //     const { schoolId } = JSON.parse(
      //       localStorage.getItem("jzjx-userInfo")
      //     ).content;
      //     this.id = res.data.data.id;
      //     this.examineForm.schoolId = schoolId;
      //     this.getSchoolTeacherList();
      //     this.examineDialogVisible = true;
      //   }
      // );
    },
    async getSchoolTeacherList() {
      this.teacherLoading = true;
      const res = await getTeacherList({ ...this.examineForm });
      this.teacherList = res.data.data;
      this.teacherLoading = false;
      this.checkList = this.teacherList.filter(
        (item) => item.id == this.teacherId
      );
    },
    async getUnionSchoolList() {
      const res = await schoollistbyexamid({
        examId: this.$route.query.id,
      });
      this.schoolList = res.data.data;
      this.examineForm.schoolId = res.data.data[0].schoolId;
    },
    examineHandleClose() {
      this.examineDialogVisible = false;
      this.checkList = [];
      this.$refs.examineForm.clearValidate();
    },
    changeSelect(index) {
      this.copyExamPaperDataList.forEach((item, key) => {
        if (key == index) {
          item.checked = true;
          this.copyExamPaperId = item.id;
        } else {
          item.checked = false;
        }
      });
      this.copyExamPaperDataList = JSON.parse(
        JSON.stringify(this.copyExamPaperDataList)
      );
    },
    getExamInfo(examId) {
      let data = {
        ifVirtual: this.$route.query.ifVirtual || false,
      };
      getExamInfo(examId, data).then((response) => {
        this.exam = response.data.data.exam;
        this.examInformation = response.data.data.exam;
        if (!this.exam) {
          this.$message.error("考试不存在！");
          this.back();
          return;
        }
        this.examPapers = response.data.data.examPapers;
        this.examPapers.map((item) => {
          // console.log(this.subjectOptions);
          this.subjectOptions.map((index) => {
            if (item.subjectId == index.subjectId) {
              item.subjectName = index.subjectName;
            }
          });
        });
        this.examPapers.forEach((item) => {
          if (this.exam.level == 2 && item.subjectId == 6) {
            item.subjectId = 11;
          }
        });
        this.examName = this.exam.name;
        this.examLevel = this.exam.level;
        if (this.currentSubjectId === 0) {
          // 初次进入页面，显示第一个科目的内容
          if (this.examPapers.length > 0) {
            this.currentExamPaper = this.examPapers[0];
            this.currentSubjectId = this.currentExamPaper.subjectId;
            this.$router.replace({
              name: this.$route.name,
              query: {
                id: this.examId,
                subId: this.currentSubjectId,
                ifVirtual: this.$route.query.ifVirtual,
              },
            });
            this.getExamPaperInfo(this.currentExamPaper.id);
          }
        } else {
          // 显示当前科目的内容
          for (let i = 0; i < this.examPapers.length; i++) {
            let examPaper = this.examPapers[i];
            if (examPaper.subjectId === this.currentSubjectId) {
              this.currentExamPaper = examPaper;
              this.getExamPaperInfo(this.currentExamPaper.id);
              break;
            }
          }
        }
      });
    },
    getNewStatus(examPaperId) {
      // this.getexampaperteacherreviewList(examPaperId);
      this.gettrainingExamine(examPaperId);
      this.getGetExampaper(examPaperId);
    },
    async getExamPaperInfo(examPaperId) {
      this.loadingExamPaper = true;

      await getExamPaperInfo(examPaperId)
        .then((response) => {
          this.currentExamPaper = response.data.data.examPaper;

          this.examPaperQuestions = response.data.data.examPaperQuestions;
          this.refreshSubjectContent();
          this.progressStep = this.currentExamPaper.statusStep;

          this.calculateStatus();
        })
        .catch(() => {
          this.loadingExamPaper = false;
        });

      // 每次刷新頁面的時候  去請求下教师回显的接口
      this.getNewStatus(examPaperId);
    },
    handleSubjectSelect(key) {
      if (this.currentSubjectId === parseInt(key)) {
        return;
      }
      this.currentSubjectId = parseInt(key);
      for (let i = 0; i < this.examPapers.length; i++) {
        let examPaper = this.examPapers[i];
        if (examPaper.subjectId === this.currentSubjectId) {
          this.currentExamPaper = examPaper;
          break;
        }
      }
      this.$router.replace({
        name: this.$route.name,
        query: {
          id: this.examId,
          subId: this.currentSubjectId,
        },
      });
      this.getExamPaperInfo(this.currentExamPaper.id);
    },
    refreshSubjectContent() {
      this.currentSubjectExamQuestions = [];

      this.currentSubjectExamQuestions = this.examPaperQuestions.map((item) => {
        // item.examPaperNum = item.examQuestionOrder;
        item.knowledgeArray = this.getKnowledgeArrayByNames(
          item.knowledgeNames
        );
        item.display = 1; // 是否显示 原题默认显示
        item.isCollapse = 0; // 是否收起状态
        item.isCollapseAnswer = 1; // 是否收起答案
        item.examPaperQuestionIds = item.id; // 试题对应的切题表数据ID，用于编辑试题功能
        item.displayOpt = 1; // 是否显示变式题
        let indexkey = 0;
        let indexExkey = 0;
        item.examQuestionOpts = item.examQuestionExtendList.map((index) => {
          if (!index.difficultyType) {
            indexkey++;
          } else {
            indexExkey++;
          }

          index.knowledgeArray = this.getKnowledgeArrayByNames(
            index.knowledgeNames
          );
          index.display = 0; // 是否显示 材料题默认隐藏
          index.isCollapse = 0; // 是否收起状态
          index.isCollapseAnswer = 1; // 是否收起答案
          index.examPaperQuestionIds = index.id; // 试题对应的切题表数据ID，用于编辑试题功能
          index.qOptNum = !index.difficultyType ? indexkey : indexExkey; // 变式题套号 分层变式题
          return index;
        });
        return item;
      });
      this.currentSubjectExamQuestions = JSON.parse(
        JSON.stringify(this.currentSubjectExamQuestions)
      );
      // this.initImg();

      this.displayAllQuestionVar = 0;
    },
    initImg(type) {
      this.$nextTick(() => {
        var jzjxImg = document.getElementsByClassName("jzjxImg");
        let jzjxImgArr = Array.from(jzjxImg);

        var jzjxAnswerImg = document.getElementsByClassName("jzjxAnswerImg");
        let jzjxAnswerImgArr = Array.from(jzjxAnswerImg);
        jzjxImgArr = jzjxImgArr.concat(jzjxAnswerImgArr);
        if (type) {
          // 答案解析  判断
          jzjxImgArr = jzjxAnswerImgArr;
        }
        let maxWidth = 1170,
          ratio = 1;
        if (jzjxImgArr.length) {
          if (jzjxImgArr[0].style.maxWidth) {
            maxWidth = this.removePxToNumber(jzjxImgArr[0].style.maxWidth);
          }
          let width = this.removePxToNumber(jzjxImgArr[0].style.width);
          if (maxWidth < width) {
            maxWidth = width;
          }
          ratio = 1170 / maxWidth;
        }
        // console.log(jzjxImgArr);
        jzjxImgArr.forEach((item) => {
          const width = this.removePxToNumber(item.style.width);
          if (item.name == "edit") return;
          item.style.width = width * ratio + "px";
          item.style.display = "block";
          item.name = "edit";
        });
      });
    },
    removePxToNumber(val) {
      let data = val.replace("px", "");
      return Number(data);
    },
    getExamQuestionById(id) {
      for (let i = 0; i < this.examQuestions.length; i++) {
        let examQuestion = this.examQuestions[i];
        if (examQuestion.id === id) {
          return JSON.parse(JSON.stringify(examQuestion));
        }
      }
    },
    getKnowledgeArrayByNames(knowledgeNames) {
      let array = [];
      if (knowledgeNames && knowledgeNames.length > 0) {
        array = knowledgeNames.split("#");
      }
      return array;
    },
    calculateStatus() {
      // 添加判断 是否自动标注
      this.knowledgeType = false;
      this.trainingType = false;

      let knowledgeArr = this.examPaperQuestions.filter(
        (item) => item.statusReplaceKnowledge == 1
      );
      let trainingArr = this.examPaperQuestions.filter(
        (item) => item.statusReplaceTraining == 1
      );
      // 判断是不是没有人工标注知识点
      if (
        knowledgeArr.length == this.examPaperQuestions.length &&
        this.examPaperQuestions.length > 0
      ) {
        this.knowledgeType = true;
      }
      // 判断是不是没有人工标注变式题
      if (
        trainingArr.length == this.examPaperQuestions.length &&
        this.examPaperQuestions.length > 0
      ) {
        this.trainingType = true;
      }
      // 添加判断 是否自动标注 end
      this.loadingExamPaper = false;
      this.statusUpload = this.progressStep > 1 ? 1 : 0;
      this.statusCut = this.progressStep > 2 ? 1 : 0;
      if (!this.examPaperQuestions || this.examPaperQuestions.length === 0) {
        this.statusKnowledge = 0;
        this.statusTraining = 0;
      } else {
        this.statusKnowledge = 1;
        this.statusTraining = 1;
        for (let i = 0; i < this.examPaperQuestions.length; i++) {
          let examPaperQuestion = this.examPaperQuestions[i];
          // let isMaterial = examPaperQuestion.isMaterial;
          let isPush = examPaperQuestion.isPush;
          if (isPush === 1) {
            if (examPaperQuestion.statusKnowledge === 0) {
              this.statusKnowledge = 0;
            }
            if (examPaperQuestion.statusTraining === 0) {
              this.statusTraining = 0;
            }
          }
        }
      }
      if (this.statusUpload === 0) {
        this.progressStep = 1;
      } else if (this.statusCut === 0) {
        this.progressStep = 2;
      } else if (this.statusKnowledge === 0) {
        this.progressStep = 3;
      } else if (this.statusTraining === 0) {
        this.progressStep = 4;
      }
      if (this.knowledgeType) {
        this.progressStep = 3;
      }
      if (this.trainingType) {
        this.progressStep = 4;
      }
      this.currentExamPaper.statusStep = this.progressStep;
      if (this.progressStep == 9) {
        this.progressStep = 4;
      }
      // 换题（试题电子化）状态。遍历切题表，有一道题已经换题该值就为1。
      this.statusReplace = 0;
      for (let i = 0; i < this.examPaperQuestions.length; i++) {
        let examPaperQuestion = this.examPaperQuestions[i];
        if (this.statusReplace === 0 && examPaperQuestion.statusReplace === 1) {
          this.statusReplace = 1;
          break;
        }
      }
    },
    clickStep(step) {
      if (step === this.progressStep) {
        return;
      }
      // if (step === 2 && this.statusReplace === 1) {
      //   this.$message.error("已有试题电子化，不能重新切题！");
      //   return;
      // }
      let realProgressStep = this.currentExamPaper.statusStep;
      if (step > realProgressStep) {
        this.$message.error("不能直接进行后面的步骤！");
        return;
      }
      this.progressStep = step;
    },
    setAnswer() {
      let examPaperId = "";
      this.examPapers.map((item) => {
        if (item.subjectId == this.$route.query.subId) {
          examPaperId = item.id;
        }
      });
      this.$router.push({
        name: "ExamCutAnswer",
        query: {
          examPaperId: examPaperId,
          examId: this.$route.query.id,
          ifVirtual: this.$route.query.ifVirtual,
        },
      });
    },
    async clickProgressStepButton() {
      // statusConvert 0 未转换 1 转换完成 2 转换中 3 转换失败
      if (this.progressStep === 1) {
        let realProgressStep = this.currentExamPaper.statusStep;
        if (this.progressStep < realProgressStep) {
          this.$confirm(
            "此操作将清除已上传试卷和已保存试题（包括答案、解析、知识点和变式题及变式题审核记录等），确定重新上传试卷吗？",
            "警告",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              this.$refs.uploadFile.init();
              // this.initUploadExamPaperDialog();
              // this.uploadExamPaperDialogVisible = true;
            })
            .catch(() => {
              this.progressStep = realProgressStep;
            });
        } else {
          this.$refs.uploadFile.init();
          // this.initUploadExamPaperDialog();
          // this.uploadExamPaperDialogVisible = true;
        }
      } else if (this.progressStep === 2) {
        if (
          this.currentExamPaper.resultPath &&
          this.currentExamPaper.resultPath.length > 0 &&
          this.currentExamPaper.statusConvert === 1
        ) {
          this.step2GotoAction();
        } else {
          getExamPaper(this.currentExamPaper.id).then((response) => {
            this.currentExamPaper = response.data.data.examPaper;
            if (
              !this.currentExamPaper.resultPath ||
              this.currentExamPaper.resultPath.length === 0
            ) {
              this.$message.error("试卷上传失败，请重新上传");
              return;
            }
            if (
              this.currentExamPaper.resultPath &&
              this.currentExamPaper.resultPath.length > 0 &&
              this.currentExamPaper.statusConvert === 1
            ) {
              this.step2GotoAction();
            } else {
              if (
                this.currentExamPaper.filePath &&
                this.currentExamPaper.filePath.length > 0 &&
                this.currentExamPaper.statusConvert === 0
              ) {
                this.$message.info("试卷转换中！");
              } else if (
                this.currentExamPaper.filePath &&
                this.currentExamPaper.filePath.length > 0 &&
                this.currentExamPaper.statusConvert === 3
              ) {
                // eslint-disable-next-line no-unused-vars

                reconvert(this.currentExamPaper.id).then(() => {
                  this.$message({
                    type: "info",
                    message:
                      "试卷转换失败，正在重新进行转换！稍后请重试。<br />如果遇到多次转换失败的情况，请考虑使用Office或其他文档处理工具，打开该文件将其另存为新的文件，然后使用新文件重新上传试卷。",
                    dangerouslyUseHTMLString: true,
                    duration: 8000,
                  });
                });
              }
            }
          });
        }
      } else if (this.progressStep === 3) {
        this.step3GotoAction();
        // if (this.currentExamPaper.statusAutoMatch === 1) {
        //   this.step3GotoAction();
        // } else {
        //   getExamPaper(this.currentExamPaper.id).then((response) => {
        //     this.currentExamPaper = response.data.data.examPaper;
        //     if (this.currentExamPaper.statusAutoMatch === 1) {
        //       this.step3GotoAction();
        //     } else {
        //       this.$message.info(
        //         "服务器正在对已切试题进行自动匹配知识点和变式题的操作！稍后请重试。"
        //       );
        //     }
        //   });
        // }
      } else if (this.progressStep === 4) {
        // if (this.currentExamPaper.statusAutoMatch === 1) {
        this.step4GotoAction();
        // } else {
        //   getExamPaper(this.currentExamPaper.id).then((response) => {
        //     this.currentExamPaper = response.data.data.examPaper;
        //     if (this.currentExamPaper.statusAutoMatch === 1) {
        //       this.step4GotoAction();
        //     } else {
        //       this.$message.info(
        //         "服务器正在对已切试题进行自动匹配知识点和变式题的操作！稍后请重试。"
        //       );
        //     }
        //   });
        // }
      }
    },
    async downLoadgetexampaperzip() {
      this.downloadBtn = true;
      try {
        const res = await getexampaperzip(this.currentExamPaper.id);

        let index = res.data.data.name.lastIndexOf(".");
        const suffix = res.data.data.name.substring(index + 1);
        const name = res.data.data.name.substring(0, index);
        const { href } = this.$router.resolve({
          path: "/downFile",
          query: {
            url: res.data.data.url,
            name:
              name +
              "(" +
              this.getSubjectName(this.$route.query.subId) +
              ")原卷." +
              suffix,
            type: 1,
          },
        });
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          clearTimeout(timer);
        }, 0);
        this.downloadBtn = false;
      } catch {
        this.downloadBtn = false;
      }
    },
    getSubjectName(subjectId) {
      let subject = this.subjectOptions.find((item) => item.value == subjectId);
      return subject ? subject.label : "";
    },
    step2GotoAction() {
      let realProgressStep = this.currentExamPaper.statusStep;
      if (this.progressStep < realProgressStep) {
        this.$confirm(
          "重新切题后需要重新组合题号，确定重新裁切试题吗？",
          "警告",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.divideExamPaper();
          })
          .catch(() => {
            this.progressStep = realProgressStep;
          });
      } else {
        this.divideExamPaper();
      }
    },
    step3GotoAction() {
      this.$store.commit("clearAll");
      this.$router.push({
        name: "examlore",
        query: {
          id: this.currentExamPaper.id, // 试卷ID
          name: this.currentExamPaper.name, // 试卷ID
          subjectId: this.currentExamPaper.subjectId, // 试卷ID
          examId: this.$route.query.id,
          ifVirtual: this.$route.query.ifVirtual,
        },
      });
    },
    step4GotoAction() {
      this.$store.commit("clearAll");
      this.$router.push({
        name: "examvariation",
        query: {
          id: this.currentExamPaper.id, // 试卷ID
          name: this.currentExamPaper.name, // 试卷ID
          subjectId: this.currentExamPaper.subjectId, // 试卷ID
          examId: this.$route.query.id,
          ifVirtual: this.$route.query.ifVirtual,
        },
      });
    },

    divideExamPaper() {
      this.loading = true;
      let filePath = this.currentExamPaper.filePath;
      let filePathArr = filePath.split(",");
      if (filePathArr[0].endsWith(".docx")) {
        // Word 文件
        this.$router.push({
          name: "ExamNewPaperDivide",
          // name: "exampaperdivide",
          query: {
            id: this.currentExamPaper.id, // 试卷ID
            name: this.currentExamPaper.name,
            subjectId: this.currentExamPaper.subjectId,
            examId: this.$route.query.id,
            ifVirtual: this.$route.query.ifVirtual,
          },
        });
      } else if (filePathArr[0].endsWith(".pdf")) {
        // PDF 文件
        this.$router.push({
          // name: "exampaperpicdivide",
          name: "examNewPaperCropper",
          query: {
            id: this.currentExamPaper.id, // 试卷ID
            name: this.currentExamPaper.name,
            subjectId: this.currentExamPaper.subjectId,
            ifVirtual: this.$route.query.ifVirtual,
          },
        });
      } else {
        // 图片文件
        this.$router.push({
          // name: "exampaperpicdivide",
          name: "examNewPaperCropper",
          query: {
            id: this.currentExamPaper.id, // 试卷ID
            name: this.currentExamPaper.name,
            subjectId: this.$route.query.subId,
            ifVirtual: this.$route.query.ifVirtual,
          },
        });
      }
    },
    clickDisplayAllQuestionVar() {
      this.displayAllQuestionVar = 1 - this.displayAllQuestionVar;
      for (let i = 0; i < this.currentSubjectExamQuestions.length; i++) {
        let question = this.currentSubjectExamQuestions[i];
        question.displayOpt = this.displayAllQuestionVar;
        for (let oi = 0; oi < question.examQuestionOpts.length; oi++) {
          let examQuestionOpt = question.examQuestionOpts[oi];
          examQuestionOpt.display = question.displayOpt;
        }
      }
    },
    clickDisplayQuestionVar(question) {
      // console.log(question);
      if (!question.displayOpt) {
        question.displayOpt = 1;
      } else {
        question.displayOpt = 0;
      }
      // question.displayOpt == 1
      //   ? (question.displayOpt = 0)
      //   : (question.displayOpt = 1);
      question.display = question.displayOpt;
      // if (question.displayOpt == 0) {
      //   return;
      // }
      // trainings(question.examPaperQuestionIds, { type: 1 }).then((res) => {
      //   this.currentSubjectExamQuestions[index].examQuestionOpts =
      //     res.data.data;

      // this.currentSubjectExamQuestions = JSON.parse(
      //   JSON.stringify(this.currentSubjectExamQuestions)
      // );
      // console.log(this.currentSubjectExamQuestions);

      // this.currentSubjectExamQuestions = JSON.parse(
      //   JSON.stringify(this.currentSubjectExamQuestions)
      // );
      // });

      // question.displayOpt = 1 - question.displayOpt;
      // for (let i = 0; i < question.examQuestionOpts.length; i++) {
      //   let examQuestionOpt = question.examQuestionOpts[i];
      //   examQuestionOpt.display = question.displayOpt;
      // }
      // if (question.displayOpt === 1) {
      //   this.displayAllQuestionVar = 1;
      // } else if (question.displayOpt === 0) {
      //   let isDoNotDisplayAll = true;
      //   for (let i = 0; i < this.currentSubjectExamQuestions.length; i++) {
      //     if (this.currentSubjectExamQuestions[i].displayOpt === 1) {
      //       isDoNotDisplayAll = false;
      //       break;
      //     }
      //   }
      //   if (isDoNotDisplayAll) {
      //     this.displayAllQuestionVar = 0;
      //   }
      // }
    },
    displayDifficulty(difficulty) {
      let str = "容易";
      if (difficulty <= 1 && difficulty >= 0.9) {
        str = "容易";
      } else if (difficulty < 0.9 && difficulty >= 0.7) {
        str = "较易";
      } else if (difficulty < 0.7 && difficulty >= 0.5) {
        str = "一般";
      } else if (difficulty < 0.5 && difficulty >= 0.3) {
        str = "较难";
      } else if (difficulty < 0.3 && difficulty >= 0) {
        str = "困难";
      }
      return str;
    },
    clickExamQuestionCollapseIcon(question, isClickContent) {
      if (isClickContent && question.isCollapse === 0) {
        return;
      }
      question.isCollapse = 1 - question.isCollapse;
    },
    clickAnswerAnalysisButton(question) {
      question.isCollapseAnswer = 1 - question.isCollapseAnswer;
      // this.initImg(1);
    },
    clickAnswerOpt(index, optIndex) {
      if (
        !this.currentSubjectExamQuestions[index].examQuestionOpts[optIndex]
          .isCollapseAnswer
      ) {
        this.currentSubjectExamQuestions[index].examQuestionOpts[
          optIndex
        ].isCollapseAnswer = 1;
      } else {
        this.currentSubjectExamQuestions[index].examQuestionOpts[
          optIndex
        ].isCollapseAnswer = 0;
      }
      this.currentSubjectExamQuestions = JSON.parse(
        JSON.stringify(this.currentSubjectExamQuestions)
      );
      // this.initImg(1);
    },
    clickEditQuestion(question) {
      this.editingExamQuestion = question;
      // 显示试题内容
      this.editorData = this.editingExamQuestion.question;
      this.editExamQuestionDialogVisible = true;
    },
    cancelEditExamQuestionDialog() {
      this.editExamQuestionDialogVisible = false;
      this.editingExamQuestion = {};
    },
    confirmEditExamQuestionDialog() {
      let eData = this.getEditorObj().getData();
      if (!eData || eData.length === 0) {
        this.$message.error("试题内容不能为空！");
        return;
      }
      let examQuestionId = 0;
      if (this.editingExamQuestion.id) {
        examQuestionId = this.editingExamQuestion.id;
      }
      let html = $(".editExamQuestionCKEditor .cke_editable").html();
      let data = new FormData();
      data.append("examPaperId", this.currentExamPaper.id);
      data.append(
        "examPaperQuestionIds",
        this.editingExamQuestion.examPaperQuestionIds
      );
      data.append("examQuestionId", String(examQuestionId));
      data.append("examPaperNum", this.editingExamQuestion.examPaperNum);
      data.append("qOptNum", this.editingExamQuestion.qOptNum);
      data.append("content", encodeURIComponent(html));
      saveEditedExamQuestion(data).then(() => {
        this.editExamQuestionDialogVisible = false;
        this.editingExamQuestion = {};
        this.getExamPaperInfo(this.currentExamPaper.id);
      });
    },
    clickEditQuestionNum(question) {
      this.editingExamQuestion = question;
      this.examQuestionNum = question.examPaperNum;
      this.editExamQuestionNumDialogVisible = true;
    },
    confirmEditExamQuestionNumDialog() {
      if (!this.examQuestionNum || this.examQuestionNum.length === 0) {
        this.$message.error("请输入题号！");
        return;
      }
      let isEdit = 0; // 是否已修改题号
      if (this.examQuestionNum !== this.editingExamQuestion.examPaperNum) {
        isEdit = 1;
      }
      if (isEdit === 0) {
        // 题号未被修改，直接关闭弹窗
        this.editExamQuestionNumDialogVisible = false;
        return;
      }
      saveEditedExamQuestionNum({
        examPaperId: this.currentExamPaper.id,
        oldExamPaperNum: this.editingExamQuestion.examPaperNum, // 修改前的试题题号
        examPaperNum: this.examQuestionNum, // 修改后的试题题号
      }).then((response) => {
        if (response.data.code === 1) {
          this.$message.error(response.data.msg);
        } else {
          this.$message({
            message: "题号修改成功",
            type: "success",
          });
          this.editingExamQuestion.examPaperNum = this.examQuestionNum;
          this.editExamQuestionNumDialogVisible = false;
        }
      });
    },
    initCKEditor() {
      if (window.CKEDITOR) {
        window.CKEDITOR.dtd.$removeEmpty["span"] = 0;
        window.CKEDITOR.dtd.$removeEmpty["a"] = 0;
        window.CKEDITOR.dtd.$removeEmpty["i"] = 0;
      }
    },
    editorReady(editor) {
      this.initCKEditor();
      this.CKEditorName = editor.name;
    },
    getEditorObj() {
      return window.CKEDITOR.instances[this.CKEditorName];
    },
    dataReady() {
      $(".editExamQuestionCKEditor .cke_editable img").removeAttr(
        "data-cke-saved-src"
      );
    },

    clickCopyExamPaper() {
      // this.copyExamPaperDataList = [];
      // this.copyExamPaperDialogVisible = true;
      this.$refs.copyExam.init();
    },

    back() {
      const routerFrom = localStorage.getItem("routerFrom");
      // console.log(routerFrom);
      if (!routerFrom) {
        this.$router.push({
          name: "examlist",
        });
      } else {
        this.$router.push({
          path: routerFrom,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/pages/exam/ExamPaper.scss";

.paper-content-body {
  padding: 18px;
}

::v-deep {
  .el-progress-circle {
    height: 10px !important;
    width: 10px !important;
  }
}

.popover-box {
  .popover-box-item {
    max-height: 300px;
  }

  .popover-item {
    display: flex;
    justify-content: space-between;
    min-width: 241px;
    margin-top: 12px;
  }
}

.paper-content {
  background-color: #ffffff;
  margin-top: 24px;

  .paper-content-box {
    background: #f9fafc;
    padding: 24px 0;
  }
}

.voluntarily {
  .paper-progress-num {
    background-color: #f57574 !important;
  }
}

.paper-progress-button {
  text-align: center;

  .dis-btn {
    background: #659ff6;
    border-color: #659ff6;
    cursor: no-drop;
  }
}

.paper-progress {
  position: relative;

  .tips-box {
    position: absolute;
    left: 34px;
    top: 23px;
    font-size: 12px;
    white-space: nowrap;

    p {
      position: relative;
      padding-left: 16px;
      margin-bottom: 0;
      margin-top: 2px;
      text-align: left;

      &::after {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background: #d5d6db;
        border-radius: 50%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .succ {
      &::after {
        background: #2474ed;
      }
    }

    .tips-err {
      &::after {
        background: #f57574 !important;
      }
    }
  }
}
</style>

<style lang="scss">
.exam-paper {
  padding-top: 24px;

  .btn-style {
    margin-left: 10px;
    color: #fff;
    background-color: #9fc3fa;
    border-color: #9fc3fa;
    padding: 7px 12px;
    user-select: none;
    border-radius: 4px;
  }

  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }

  .paper-content-header {
    border-bottom: solid 1px #e6e6e6;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-box {
      flex-shrink: 0;
    }
  }
}

.exam-paper-title {
  .el-form-item__label {
    font-size: 24px;
  }
}

.paper-question-content {
  ::v-deep .jzjxImg {
    max-width: 100% !important;
  }
}

.jzjxImg {
  max-width: 100% !important;
  height: auto !important;
  display: block;
  // margin: 0 auto;
}

.upload-exam-paper-dialog-content .upload-button .el-upload .el-upload-dragger {
  width: 540px;
}

.upload-exam-paper-dialog-content .upload-button .el-upload-list {
  max-height: 100px;
  overflow-y: auto;
}

.editExamQuestionCKEditor .cke_editable {
  height: 300px;
  padding: 5px;
  font-size: 14px;
  border: solid 1px #e0e0e0;
  overflow-x: auto;
  overflow-y: auto;
}

.translate-box {
  display: flex;
  align-items: flex-start;

  .box {
    flex: 1;

    .header {
      background-color: #f5f7fa;
      box-sizing: border-box;
      padding: 15px 24px;
      font-size: 14px;
      color: #101011;
      border: 1px solid #dee1e3;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        color: #606266;
      }

      .clearAll {
        color: #d92020;
        cursor: pointer;
      }
    }

    .content {
      border: 1px solid #dee1e3;
      border-top: none;
      box-sizing: border-box;
      padding: 24px;
      height: 279px;
      overflow-y: auto;

      .el-checkbox {
        margin-right: 0;
        display: block;
        margin-bottom: 24px;
      }

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #2474ed;

        i {
          color: #101011;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
